import React, {useState} from 'react';
import axios from "axios";
import {apiBaseUrl} from "../consts";

const SignIn = ({ onSignIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');



    const handleSubmit = async (event) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        try {
            const response = await axios.post(apiBaseUrl+'/token', formData, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });

            const token = response.data.access_token;
            const userName = response.data.user_name;
            localStorage.setItem('token', token);
            localStorage.setItem('userName', userName);
            onSignIn()

        } catch (error) {

            setError(error.response?.data?.message || 'Giriş bilgileri hatalı veya sunucu hatası meydana geldi.');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">

            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                <div className="w-full flex flex-row items-center">
                    <img src={"images/saglikbanklogo.jpg"} alt="Logo" className="h-full object-contain text-center" />
                </div>
                <h3 className="text-2xl font-bold text-center">Giriş Yap</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mt-4">
                        <div>
                            <label className="block" htmlFor="username">Kullanıcı Adı</label>
                            <input
                                type="text"
                                placeholder="Kullanıcı Adı"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                required
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block">Şifre</label>
                            <input
                                type="password"
                                placeholder="Şifre"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="flex items-baseline justify-between">
                            <button className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Giriş Yap</button>
                            <a href="/reset-password" className="text-sm text-blue-600 hover:underline">Şifrenizi mi unuttunuz?</a>
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
