import React from 'react';
import axios from 'axios';
import {saveAs} from 'file-saver';
import {apiBaseUrl} from "../consts";
import {useOutletContext} from "react-router-dom";

function DownloadFile({resultId, fileName}) {
    const { showError } = useOutletContext();
    const downloadFile = async (resultId) => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
            const response = await axios.get(`${apiBaseUrl}/downloadFile/${resultId}`, {
                responseType: 'blob', // Dosyayı bir blob olarak almak için
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekle
                }
            });

            // Content-Disposition başlığından dosya adını al
            let contentDisposition = response.headers['content-disposition'];

            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];

                    // Dosya adının sonundaki ekstra `"` karakterini ve UUID'yi kaldır
                    fileName = fileName.replace(/"$/, '').replace(/_[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\.[^.]+)$/, '$1');
                }
            }

            // Blob nesnesini bir dosya olarak kaydet
            saveAs(response.data, fileName);
        } catch (error) {
            showError('Dosya indirilirken bir hata oluştu');
        }
    };


    return (
        <div className="flex justify-center mt-5">
            <button
                className="mr-4 mb-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                onClick={() => downloadFile(resultId)}>
                Dosya İndir
            </button>
        </div>
    );
}
export default DownloadFile;
