import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {apiBaseUrl} from "../consts";
import {XMarkIcon} from "@heroicons/react/16/solid";

const ReportIssueModal = ({show, onClose, resultId}) => {
    const [issue, setIssue] = useState('');

    const fetchIssue = (id) => {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
        axios.get(`${apiBaseUrl}/getResultIssue?result_id=${id}`, { // result_id sorgu parametresi olarak ekleniyor
            headers: {
                Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
            }
        })
            .then(response => {
                setIssue(response.data);
            })
            .catch(error => {
                console.error("Sorun getirilirken bir hata oluştu.", error);
                alert("Sorun getirilirken bir hata oluştu.")
            });
    };


    useEffect(() => {
        if (show) {
            fetchIssue(resultId);
        }
    }, [show, resultId]);
    if (!show) return null;

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');

        try {
            // POST isteği için URL'i ve gönderilecek veriyi değiştirin.
            await axios.post(apiBaseUrl + '/reportResult', {result_id: resultId, report_text: issue},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            onClose(); // Sorun başarıyla bildirildikten sonra modalı kapat
        } catch (error) {
            alert("Sorun bildirilirken bir hata oluştu.");
            console.error(error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white rounded-lg w-11/12 md:max-w-md mx-auto">
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="text-red-400 hover:text-red-600 font-bold px-2 rounded"
                    >
                        <XMarkIcon className="h-6 w-6"/>
                    </button>
                </div>
                <div className="py-4 text-center">
                    <h2 className="text-2xl font-semibold">Sorun Bildir</h2>
                </div>
                <div className="px-4 py-2">
                    <textarea
                        className="w-full p-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-yellow-500"
                        rows="4"
                        placeholder="Lütfen yaşadığınız sorunu buraya yazın..."
                        value={issue}
                        onChange={(e) => setIssue(e.target.value)}
                    ></textarea>
                </div>

                <div className="flex justify-end p-4">
                    <button
                        onClick={handleSubmit}
                        className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Gönder
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportIssueModal;
