import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { debounce } from 'lodash';
import { apiBaseUrl } from "../consts";
import { useOutletContext } from "react-router-dom";

function InstitutionSearch({ apiUrl, debounceTime = 300, placeholder, onInstitutionSelect, selectedId, onClear, isClearable = true }) {
    const [inputValue, setInputValue] = useState('');
    const { showError, showWarning, showInformation } = useOutletContext();
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (selectedId) {
            fetchInstitutionById(selectedId);
        }
    }, [selectedId]);

    const fetchInstitutionById = async (id) => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alıyoruz
            const response = await axios.get(`${apiBaseUrl}/getInstitutionById`, {
                params: { institution_id: id },
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekliyoruz
                }
            });
            const institution = response.data;
            const option = {
                label: institution.institutionNameInSB,
                value: institution
            };
            setSelectedOption(option);
            setInputValue(option.label);
        } catch (error) {

        }
    };

    const loadOptions = (inputValue, callback) => {
        if (inputValue.length < 5) return callback([]);

        axios.get(`${apiBaseUrl}/${apiUrl}?institution_name=${inputValue}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(response => {
                const options = response.data.map(institution => ({
                    label: institution.institutionNameInSB,
                    value: institution
                }));
                callback(options);
            })
            .catch(error => {
                showError('Kurumlar yüklenirken bir hata oluştu');
                callback([]);
            });
    };

    const debouncedLoadOptions = debounce(loadOptions, debounceTime);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setInputValue(selectedOption ? selectedOption.label : '');
        if (selectedOption) {
            onInstitutionSelect(selectedOption.value);
        } else {
            // selectedOption null ise, yani kullanıcı değeri temizlediyse
            if(onClear) {
                onClear();
            }
        }
    };


    const handleInputChange = (newValue) => {
        setInputValue(newValue);
        return newValue;
    };

    const isValidNewOption = (inputValue) => {
        return inputValue.length >= 10; // Minimum 10 karakter gereksinimi
    };

    const handleCreateOption = (newValue) => {
        axios.post(`${apiBaseUrl}/createInstitution`, {
            institutionNameInSB: newValue
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(response => {
                const newOption = {
                    label: response.data.institutionNameInSB,
                    value: response.data
                };
                handleChange(newOption);
            })
            .catch(error => {
                if (error.response?.status === 403) {
                    showWarning(`${error.response.data?.detail}`);
                } else if (error.response?.status === 400) {
                    showWarning(`${error.response.data?.detail}`);
                } else {
                    showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
                }
            });
    };

    return (
        <AsyncCreatableSelect
            cacheOptions
            loadOptions={debouncedLoadOptions}
            onInputChange={handleInputChange}
            onChange={handleChange}
            onCreateOption={handleCreateOption}
            defaultOptions
            value={selectedOption}
            placeholder={placeholder}
            noOptionsMessage={() => 'Bir sonuç bulunamadı'}
            isClearable={isClearable}
            formatCreateLabel={(inputValue) => `Yeni oluştur: "${inputValue}"`}
            onClear={onClear}
            isValidNewOption={isValidNewOption}
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: '40px',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderColor: 'gray',
                    boxShadow: 'none',
                    '&:hover': {
                        borderColor: 'blue'
                    }
                }),
                dropdownIndicator: base => ({
                    ...base,
                    color: 'gray',
                    '&:hover': {
                        color: 'black'
                    }
                }),
                clearIndicator: base => ({
                    ...base,
                    color: 'red',
                    '&:hover': {
                        color: 'darkred'
                    }
                }),
            }}
        />
    );
}

export default InstitutionSearch;
