import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useOutletContext, useParams, useNavigate, useLocation} from 'react-router-dom';
import {apiBaseUrl} from "../consts";
import {
    ChevronLeftIcon, ChevronRightIcon, PlusIcon, MinusIcon, ArrowPathIcon, ExclamationCircleIcon
} from "@heroicons/react/16/solid";
import ReportIssueModal from "./ReportIssueModal";
import DownloadFile from "./DownloadFile";
import DocumentViewer from "./DocumentViewer";
import InstitutionSearch from "./InstitutionSearch";


const Result = () => {
    const [formData, setFormData] = useState({
        id: '',
        jobId: '',
        nodes: [],
        institutionName: '',
        subjectOfTender: '',
        tenderNumber: '',
        offerDate: '',
        offerTime: '',
        tenderType: '',
        institutionPhone: '',
        institutionFax: '',
        institutionEmail: '',
        institutionAddress: '',
        submissionsPlace: '',
        deliveryPlace: '',
        deliveryDuration: '',
        deliveryDate: '',
        projectStartDate: '',
        domesticAdvantageExists: '',
        domesticAdvantageRate: '',
        isE_Tender: '',
        isE_Offer: '',
        isE_Reduction: '',
        partialOffersAllowed: '',
        tenderSummary: '',
        listOfItems: [],
        tenderAnnouncement: '',
        status: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showDocument, setShowDocument] = useState(true);
    const [activeTab, setActiveTab] = useState('tab3');
    const [isNodesVisible, setIsNodesVisible] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {result_id} = useParams();
    const {showError, showWarning, showInformation} = useOutletContext();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        // If result_id exists in the URL, use it to fetch data
        if (result_id) {
            fetchTenderWithResultId(result_id);
        } else {
            // If no result_id, fetch the next new result
            getNextNewResult();
        }
    }, [result_id]); // This effect depends on result_id

    useEffect(() => {
        adjustTextareaHeight();
    }, [formData.listOfItems, activeTab]);

    const updateItemsWithItemId = (data) => {
        let itemId = 0; // Initialize a counter to generate unique IDs
        const listOfItems = data.listOfItems.map(item => {
            if (!item.id) { // Check if the item already has an ID
                item.id = `item-${++itemId}`; // Assign a unique ID if not present
            }
            return item;
        });
        return {...data, listOfItems};
    };


    const groupedItems = formData.listOfItems.reduce((acc, item) => {
        // Bölüm değerine göre gruplama
        acc[item.section] = acc[item.section] || [];
        acc[item.section].push(item);
        return acc;
    }, {});

    const fetchTenderWithResultId = (id) => {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
        axios.get(apiBaseUrl + `/results/${id}`, {
            headers: {
                Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
            }
        })
            .then(response => {
                const updatedResult = updateItemsWithItemId(response.data);
                setFormData(updatedResult);
                console.log(response.data);
                return response.data;
            })
            .then((r) => {
                signResultAsSeen(r.id)
            }).catch(error => {
            showError(error.response ? error.response.data.detail : 'Bir hata meydana geldi!');
        });
    };

    const getNextNewResult = () => {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
        axios.get(apiBaseUrl + '/getNextNewResult', {
            headers: {
                Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
            }
        })
            .then(response => {
                setFormData(response.data);
                console.log(response.data);
                return response.data;
            })
            .then((r) => {
                // Burada da token'ı göndermeniz gerekecek
                if (r?.id) {
                    signResultAsSeen(r.id)
                }
            }).catch(error => {
            if (error.response && error.response.status === 404) {
                showInformation('Yeni ihale bilgisi bulunamadı');
            } else {
                showError(error.response ? error.response.data.detail : 'Bir hata meydana geldi!');
            }
        });
    }

    const signResultAsSeen = (resultId) => {

        if (resultId) {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
            axios.post(apiBaseUrl + '/signResultAsSeen', resultId, {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                }
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    // showWarning(error.response.data.detail);
                } else {
                    showError(error.data);
                }
            });
        }
    }


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState, [name]: value
        }));
        if (name === 'institutionNameInNotice') {
            setIsButtonDisabled(false)
        }
    };

    const handleItemChange = (id, e) => {
        const {name, value} = e.target;

        const updatedItems = formData.listOfItems.map((item) => {
            if (item.id === id) { // Use the unique ID for comparison
                return {...item, [name]: value};
            }
            return item;
        });

        setFormData(prevState => ({
            ...prevState, listOfItems: updatedItems
        }));
    };


    const addItem = (section = '', sequenceNo = 0) => {
        setFormData(prevState => {
            // Generate a new ID based on the last item in the list
            const newId = `new-item-${Date.now()}`;

            // Create the new item with incremented sequence_no
            const newItem = {
                id: newId, sequence_no: sequenceNo + 1, item: '', amount: '', unit: '', explanation: '', section
            };

            // Add the new item to the list and sort it
            const newList = [...prevState.listOfItems, newItem].sort((a, b) => a.sequence_no - b.sequence_no);

            return {
                ...prevState, listOfItems: newList
            };
        });
    };


    const removeItem = (id) => {
        setFormData(prevState => ({
            ...prevState, listOfItems: prevState.listOfItems.filter((_) => _.id !== id)
        }));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);
    //     axios.post('your_backend_endpoint', formData)
    //         .then(response => {
    //             // Handle success
    //             console.log(response.data);
    //         })
    //         .catch(error => {
    //             // Handle error
    //             console.error('Error:', error);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        function toUpperCaseTR(input) {
            const trMap = {
                'i': 'İ', 'ı': 'I', 'ğ': 'Ğ', 'ü': 'Ü', 'ş': 'Ş', 'ö': 'Ö', 'ç': 'Ç'
            };
            return input.replace(/[iışğüçö]/g, function (match) {
                return trMap[match];
            }).toUpperCase();
        }

        // FormData'nın null olmayan değerlerini filtreleme ve formatlama
        let formattedText = "";
        if (formData.tenderAnnouncement) {
            // İhale duyurusunu paragraflara ayır
            const paragraphs = formData.tenderAnnouncement.split(/\n\s*\n/);
            // İlk paragraf için özel biçimlendirme
            let firstParagraphContent = paragraphs[0];
            // ilk paragraftaki boşlukları htmle çevirme
            firstParagraphContent = firstParagraphContent.replace(/\n/g, '<br>');

            // "T.C." ifadesini kontrol edip gerektiğinde yeni satıra taşıma
            if (firstParagraphContent.includes('T.C')) {
                firstParagraphContent = firstParagraphContent.replace(/T\.C\.\s*(<br>|\n)*/g, 'T.C.<br>');
            }

            const firstParagraph = `<p style="text-align: center;"><font size="3">${toUpperCaseTR(firstParagraphContent)}</font></p>`;

            // İlk paragraf hariç, geri kalan paragraflar için işleme
            const wrappedParagraphs = paragraphs.slice(1).map(paragraph => {
                const innerParagraphs = paragraph.split('\n');
                const formattedParagraphs = innerParagraphs.map(innerParagraph => {
                    // Eğer paragraf boş ise, boşluk olarak işlenecek bir <br> ekleyerek boşluğu koru
                    if (innerParagraph.trim() === '') {
                        return '<br>';
                    }
                    // Paragraf boş değilse, içeriği <font size="3"> ile sarmala
                    return `<font size="3">${processStrong(innerParagraph)}</font><br>`;
                });
                return formattedParagraphs.join('');
            });
            // Sarmalanmış paragrafları (ve boş satırları) yeniden birleştir
            formattedText = `${firstParagraph}<br><br><br>${wrappedParagraphs.join('<br>')}<br>`;
        }


        if (formData.tenderSummary && !formData.tenderNumber?.includes("24DT")) {
            // İhale duyurusunu paragraflara ayır
            const paragraphs = formData.tenderSummary.split('\n');
            // Boş satırları koruyarak her bir paragrafı <font size="3">...</font> ile sarmala
            const wrappedParagraphs = paragraphs.map(paragraph => {
                // Eğer paragraf boş ise, boşluk olarak işlenecek bir <br> ekleyerek boşluğu koru
                if (paragraph.trim() === '') {
                    return '<br>';
                }
                // Paragraf boş değilse, içeriği <font size="3"> ile sarmala
                return `<font size="3">${processStrong(paragraph)}</font>`;
            });
            // Sarmalanmış paragrafları (ve boş satırları) yeniden birleştir
            // İhale Özeti (Beta) başlığını ve son notu ekleyerek formatla
            formattedText += `<hr><font size="3"><strong>İhale Özeti (Beta)</strong></font><br><br>${wrappedParagraphs.join('<br>\n')}<br>\n<br>`;
            formattedText += `<font size="3">ÖNEMLİ NOT: Bu özet Sağlıkbank tarafından, yapay zeka teknolojileri kullanılarak bilgilendirme amaçlı üretilmiştir.</font><hr> <br><br>`;
        }


        // İhale duyurusunu ekler


        // if (formData.tenderSummary) {
        //     formattedText += `\n${formData.tenderSummary}\n\n`;
        // }
        //
        // Object.keys(formData).forEach(key => {
        //     if (formData[key] && formData[key].toString().trim() !== "" && key !== "listOfItems"
        //         && key !== "tenderAnnouncement" && key !== "nodes" && key !== "id" && key !== "tenderSummary" && key !== "jobId") {
        //         const value = typeof formData[key] === 'boolean'
        //             ? (formData[key] ? 'Evet' : 'Hayır')
        //             : formData[key];
        //         const turkishKey = turkishTranslations[key] || key; // Çeviri bulunamazsa orijinal anahtarı kullan
        //         formattedText += `${turkishKey}: ${value}\n`;
        //     }
        // });
        const announcement = formattedText


        if (formData.listOfItems.length > 0) {
            // Öncelikle bölümlere göre gruplandırma yap
            const groupedItems = formData.listOfItems.reduce((acc, item) => {
                const section = item.section || ''; // Eğer section bilgisi yoksa '' olarak grupla
                acc[section] = acc[section] || [];
                acc[section].push(item);
                return acc;
            }, {});
            let tableHtml = `<table border="1"><caption></caption><tbody>`; // Her bölüm için bir tablo başlat
            tableHtml += '<tr><td>Sıra No</td><td>Cinsi</td><td>Miktarı</td><td>Birim</td><td>Açıklama</td></tr>'; // Başlık satırı
            if (Object.keys(groupedItems).length > 1) {  // Eğer gruplanmış ögelerin anahtar sayısı 1'den fazla ise
                Object.keys(groupedItems).forEach(section => {
                    if (section) {
                        // Her bölüm için bir başlık ekleyin
                        tableHtml += `<tr><td></td><td><strong>${section}</strong></td><td></td><td></td><td></td></tr>`;
                    }

                    groupedItems[section].forEach((item, index) => {
                        // Her öge için bir satır ekleyin
                        tableHtml += `<tr><td>${item.sequence_no}</td><td>${item.item}</td><td>${item.amount}</td><td>${item.unit}</td><td>${item.explanation || ''}</td></tr>`;
                    });
                });
            } else {
                Object.keys(groupedItems).forEach(section => {
                    groupedItems[section].forEach((item, index) => {
                        // Her öge için bir satır ekleyin
                        tableHtml += `<tr><td>${item.sequence_no}</td><td>${item.item}</td><td>${item.amount}</td><td>${item.unit}</td><td>${item.explanation || ''}</td></tr>`;
                    });
                });
            }
            tableHtml += '</tbody></table>';
            formattedText += `\n\n${tableHtml}`;
            // formattedText artık tüm tabloları içerir, her biri kendi bölümüyle gruplandırılmış
        }


        // // Metni panoya kopyala ssl gerektiriyor
        // navigator.clipboard.writeText(formattedText)
        //     .then(() => {
        //         console.log("Metin panoya kopyalandı!");
        //         // Burada başarılı olduğuna dair bir mesaj veya uyarı gösterebilirsiniz.
        //     })
        //     .catch(err => {
        //         console.error('Panoya kopyalama hatası:', err);
        //         // Burada hata olduğuna dair bir mesaj veya uyarı gösterebilirsiniz.
        //     })
        //     .finally(() => {
        //         setIsLoading(false);
        //     });
        copyToClipboard(formattedText)
            .then(() => {

                // Burada başarılı olduğuna dair bir mesaj veya uyarı gösterebilirsiniz.
            })
            .catch(err => {
                showError('Panoya kopyalama hatası:', err);
                // Burada hata olduğuna dair bir mesaj veya uyarı gösterebilirsiniz.
            })
            .finally(() => {
                setIsLoading(false);
            });
        return announcement;
    };

    // Alternatif kopyalama fonksiyonu
    function copyToClipboard(text) {
        return new Promise((resolve, reject) => {
            const textArea = document.createElement('textarea');
            textArea.style.position = 'fixed'; // Position off-screen
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.width = '1px';
            textArea.style.height = '1px';
            textArea.style.padding = '0';
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'transparent';
            textArea.style.fontFamily = '"Times New Roman", serif';
            textArea.style.fontSize = '3';

            textArea.value = processText(text);
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                document.body.removeChild(textArea);
                successful ? resolve() : reject('Copy command was unsuccessful');
            } catch (err) {
                document.body.removeChild(textArea);
                reject(err);
            }
        });
    }

    const processText = (text) => {
        return text
            .replace(/\^t/g, " ") // Tab karakterini boşluk ile değiştirir
            .replace(/\^l/g, "\n") // Satır sonu karakterini yeni satır ile değiştirir
            .replace(/µ/g, "u") // Mikro simgesini "u" ile değiştirir
            .replace(/cevaplamayanfirmaların/g, "cevaplamayan firmaların") // Belirli bir kelime düzenini kelimeler arası boşluk ekleyerek değiştirir
            .replace(/[àáâãäå]/g, "a") // Tüm accentli "a" karakterlerini "a" ile değiştirir
            .replace(/[èéêë]/g, "e") // Tüm accentli "e" karakterlerini "e" ile değiştirir
            .replace(/[ìíîï]/g, "i") // Tüm accentli "i" karakterlerini "i" ile değiştirir
            .replace(/[òôõ]/g, "o") // Tüm accentli "o" karakterlerini "o" ile değiştirir
            .replace(/[ùúû]/g, "u") // Tüm accentli "u" karakterlerini "u" ile değiştirir
            .replace(/[×]/g, "x") // Tüm accentli "x" karakterlerini "x" ile değiştirir
            .replace(/[±]/g, "+-") // Tüm accentli "+-" karakterlerini "+-" ile değiştirir
            .replace(/[Ø]/g, "") // Tüm accentli "Ø" karakterlerini "Ø" ile değiştirir
            .replace(/²/g, "2") // Kare simgesini "2" ile değiştirir
            .replace(/³/g, "3") // Küp simgesini "3" ile değiştirir
            .replace(/¹/g, "1") // Birinci dereceden simgesini "1" ile değiştirir
            .replace(/[®™©º]/g, "") // Tescil, ticari marka, telif hakkı ve derece simgelerini kaldırır
            .replace(/¼/g, "1/4") // Kesir simgesini "1/4" ile değiştirir
            .replace(/½/g, "1/2") // Kesir simgesini "1/2" ile değiştirir
            .replace(/¾/g, "3/4") // Kesir simgesini "3/4" ile değiştirir
            .replace(/~/g, "") // Tilde simgesini kaldırır
            .replace(/ \^p/g, "^p") // Boşluk ile başlayan yeni satır karakterlerini düzeltir
            .replace(/ \^p\^p/g, "^p") // Ardışık yeni satır karakterlerini tek bir yeni satır karakterine düzeltir
            .replace(/Doğrudan Temin Birimi - Temin Görüntülendi/g, "") // Belirli bir metin kalıbını siler
            .replace(/Şartname Şartnameyi İndir/g, "") // "Şartname Şartnameyi İndir" metnini siler
            .replace(/\-\[TASLAK\]/g, "") // "-[TASLAK]" metnini siler
            .replace(/Bu İhaleyi İzlemeye Al/g, "") // "Bu İhaleyi İzlemeye Al" metnini siler
            .replace(/Bu İhaleye Ait İtirazen Şikayet Başvuru Bedeli için tıklayınız\./g, "") // İlgili metni siler
            .replace(/Bir Bakışta İhale/g, "") // "Bir Bakışta İhale" metnini siler
            .replace(/Db Server'a Bağlanamadınız/g, "") // "Db Server'a Bağlanamadınız" metnini siler
            .replace(/SATIN ALMA SÜREÇLERİ TAKİBİ/g, "") // "SATIN ALMA SÜREÇLERİ TAKİBİ" metnini siler
            .replace(/İhale Durumu Sonuçlanmamış/g, "") // "İhale Durumu Sonuçlanmamış" metnini siler
            .replace(/ kdv /g, " KDV ") // "kdv" yazımını "KDV" olarak değiştirir
            .replace(/ Kdv /g, " KDV ") // "Kdv" yazımını "KDV" olarak değiştirir
            .replace(/ k.d.v /g, " KDV ") // "k.d.v" yazımını "KDV" olarak değiştirir
            .replace(/ K.d.v /g, " KDV ") // "K.d.v" yazımını "KDV" olarak değiştirir
            .replace(/ Tl /g, " TL ") // " Tl " yazımını " TL " olarak değiştirir
            .replace(/ tl /g, " TL ") // " tl " yazımını " TL " olarak değiştirir
            .replace(/ :tl /g, " :TL ") // " :tl " yazımını " :TL " olarak değiştirir
            .replace(/tl /g, "TL ") // "tl " yazımını "TL " olarak değiştirir
            .replace(/Tl /g, "TL ") // "Tl " yazımını "TL " olarak değiştirir
            .replace(/ :Tl /g, " :TL ") // " :Tl " yazımını " :TL " olarak değiştirir
            .replace(/adet/g, "Adet") // "adet" yazımını "Adet" olarak değiştirir
            .replace(/ADET/g, "Adet") // "ADET" yazımını "Adet" olarak değiştirir
            .replace(/\(AD\.\)/g, "Adet") // "(AD.)" yazımını "Adet" olarak değiştirir
            .replace(/kg\./g, " KG ") // "kg." yazımını " KG " olarak değiştirir
            .replace(/Kg\./g, " KG ") // "Kg." yazımını " KG " olarak değiştirir
            .replace(/ kg/g, " KG ") // " kg" yazımını " KG " olarak değiştirir
            .replace(/kg /g, "KG ") // "kg " yazımını "KG " olarak değiştirir
            .replace(/ kg /g, " KG ") // " kg " yazımını " KG " olarak değiştirir
            .replace(/konu:/g, "Konu:") // "konu:" yazımını "Konu:" olarak düzeltir
            .replace(/KONU:/g, "Konu:") // "KONU:" yazımını "Konu:" olarak düzeltir
            .replace(/KONU :/g, "Konu:") // "KONU :" yazımını "Konu:" olarak düzeltir
            .replace(/konu :/g, "Konu:") // "konu :" yazımını "Konu:" olarak düzeltir
            .replace(/sayı:/g, "Sayı:") // "sayı:" yazımını "Sayı:" olarak düzeltir
            .replace(/SAYI:/g, "Sayı:") // "SAYI:" yazımını "Sayı:" olarak düzeltir
            .replace(/SAYI :/g, "Sayı:") // "SAYI :" yazımını "Sayı:" olarak düzeltir
            .replace(/sayı :/g, "Sayı:") // "sayı :" yazımını "Sayı:" olarak düzeltir
            .replace(/tarih:/g, "Tarih:") // "tarih:" yazımını "Tarih:" olarak düzeltir
            .replace(/TARİH:/g, "Tarih:") // "TARİH:" yazımını "Tarih:" olarak düzeltir
            .replace(/TARİH :/g, "Tarih:") // "TARİH :" yazımını "Tarih:" olarak düzeltir
            .replace(/tarih :/g, "Tarih:") // "tarih :" yazımını "Tarih:" olarak düzeltir
            .replace(/usül:/g, "Usül:") // "usül:" yazımını "Usül:" olarak düzeltir
            .replace(/USÜL:/g, "Usül:") // "USÜL:" yazımını "Usül:" olarak düzeltir
            .replace(/USÜL :/g, "Usül:") // "USÜL :" yazımını "Usül:" olarak düzeltir
            .replace(/usül :/g, "Usül:") // "usül :" yazımını "Usül:" olarak düzeltir
            .replace(/usûl/g, "usül") // "usûl" yazımını "usül" olarak düzeltir
            .replace(/hasta:/g, "Hasta:") // "hasta:" yazımını "Hasta:" olarak düzeltir
            .replace(/HASTA:/g, "Hasta:") // "HASTA:" yazımını "Hasta:" olarak düzeltir
            .replace(/HASTA :/g, "Hasta:") // "HASTA :" yazımını "Hasta:" olarak düzeltir
            .replace(/hasta :/g, "Hasta:") // "hasta :" yazımını "Hasta:" olarak düzeltir
            .replace(/ \^p\^p/g, "^p") // Condense multiple paragraph marks to one
            .replace(/\^p\^p/g, "^p") // Condense multiple paragraph marks to one
            .replace(/\.{2,}/g, " ") // Replace sequences of two or more dots with a single space
            .replace(/ {2,}/g, " ") // Replace multiple spaces with a single space
            .replace(/_{2,}/g, "") // Remove sequences of two or more underscores
            .replace(/-{2,}/g, " ") // Replace sequences of two or more dashes with a single space
            .replace(/ \^p\^p/g, "^p") // Condense spaces before multiple paragraph marks to one
            .replace(/\^p\^p/g, "^p") // Condense multiple paragraph marks to one
            .replace(/ {2,}/g, " ") // Replace multiple spaces with a single space
            .replace(/ : /g, ":") // Remove spaces before and after colons
            .replace(/ \/ /g, "/") // Remove spaces before and after slashes
            .replace(/ {2,}/g, " ") // Replace multiple spaces with a single space
            .trim(); // Metnin başındaki ve sonundaki boşlukları temizler
    };

    function processStrong(text) {
        // Genişletilmiş telefon numaraları için düzenli ifade
        const phoneRegex = /(\b0\d{10}\b|\+\d{1,3}\s?\(?\d{1,3}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,4}(?:[\s.-]?\d{1,4})?|\b\d{2,4}[\s.-]\d{3,4}[\s.-]\d{2,3}[\s.-]\d{2,4}\b)/g;
        // E-posta adresleri için düzenli ifade
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
        // Tarihler için düzenli ifade
        const dateRegex = /(\b\d{1,2}[\/.-]\d{1,2}[\/.-]\d{2,4}\b)/g;
        // Saatler için genişletilmiş düzenli ifade
        const timeRegex = /(\b(?:[01]?\d|2[0-3])[:.][0-5]?\d(?:\s?[AP]M)?(?:'a|'e|'ye|'den|'dan|'te|'ta)?\b)/gi;
        // Markdown ** ** arasında kalan kelimeler için düzenli ifade
        const markdownBoldRegex = /(\*\*(.*?)\*\*)/g;
        // Markdown * * arasında kalan kelimeler için düzenli ifade
        const markdownItalicRegex = /(\*(.*?)\*)/g;
        // Markdown #### başlıkları için düzenli ifade
        const markdownH4Regex = /#### (.*?)(\n|$)/g;
        // Markdown ### başlıkları için düzenli ifade
        const markdownH3Regex = /### (.*?)(\n|$)/g;
        // Markdown ## başlıkları için düzenli ifade
        const markdownH2Regex = /## (.*?)(\n|$)/g;
        // Markdown # başlıkları için düzenli ifade
        const markdownH1Regex = /# (.*?)(\n|$)/g;
        // Markdown bağlantıları için düzenli ifade
        const markdownLinkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;
        // Markdown kod blokları için düzenli ifade
        const markdownCodeBlockRegex = /```([^```]+)```/g;
        // Markdown satır içi kod için düzenli ifade
        const markdownInlineCodeRegex = /`([^`]+)`/g;

        // Telefon numaralarını <strong> tagi ile sarmala
        text = text.replace(phoneRegex, '<strong>$1</strong>');
        // E-posta adreslerini <strong> tagi ile sarmala
        text = text.replace(emailRegex, '<strong>$1</strong>');
        // Tarihleri <strong> tagi ile sarmala
        text = text.replace(dateRegex, '<strong>$1</strong>');
        // Saatleri <strong> tagi ile sarmala
        text = text.replace(timeRegex, '<strong>$1</strong>');
        // Markdown biçiminde ** arasına alınmış sözcükleri <strong> tagi ile sarmala
        text = text.replace(markdownBoldRegex, '<strong>$2</strong>');
        // Markdown biçiminde * arasına alınmış sözcükleri <em> tagi ile sarmala
        text = text.replace(markdownItalicRegex, '<em>$2</em>');
        // Markdown ### başlıklarını <h4> tagi ile sarmala
        text = text.replace(markdownH4Regex, '<h4>$1</h4>');
        // Markdown ### başlıklarını <h3> tagi ile sarmala
        text = text.replace(markdownH3Regex, '<h3>$1</h3>');
        // Markdown ## başlıklarını <h2> tagi ile sarmala
        text = text.replace(markdownH2Regex, '<h2>$1</h2>');
        // Markdown # başlıklarını <h1> tagi ile sarmala
        text = text.replace(markdownH1Regex, '<h1>$1</h1>');
        // Markdown bağlantılarını <a> tagi ile sarmala
        text = text.replace(markdownLinkRegex, '<a href="$2">$1</a>');
        // Markdown kod bloklarını <pre><code> tagleri ile sarmala
        text = text.replace(markdownCodeBlockRegex, '<pre><code>$1</code></pre>');
        // Markdown satır içi kodlarını <code> tagi ile sarmala
        text = text.replace(markdownInlineCodeRegex, '<code>$1</code>');

        return text;
    }


    const adjustTextareaHeight = () => {
        document.querySelectorAll('textarea[name="explanation"]').forEach(textarea => {
            textarea.style.height = 'auto'; // Yüksekliği önce auto'ya ayarla
            textarea.style.height = textarea.scrollHeight + 'px'; // Sonra scrollHeight ile ayarla
        });
        document.querySelectorAll('textarea[name="item"]').forEach(textarea => {
            textarea.style.height = 'auto'; // Yüksekliği önce auto'ya ayarla
            textarea.style.height = textarea.scrollHeight + 'px'; // Sonra scrollHeight ile ayarla
        });
    };

    // formData.listOfItems değiştiğinde veya sayfa yüklendiğinde textarea yüksekliklerini ayarla
    const checkSequence = (listOfItems) => {
        // Öğeleri 'section' değerlerine göre gruplandır
        const groupedBySection = listOfItems.reduce((acc, item) => {
            const section = item.section || ''; // Eğer 'section' bilgisi yoksa 'Diğer' olarak ata
            acc[section] = acc[section] || [];
            acc[section].push(item);
            return acc;
        }, {});

        const errors = {};

        // Her bir grup için eksik sıra numaralarını ve sıralama hatalarını bul
        for (const section in groupedBySection) {
            const items = groupedBySection[section];
            const sortedSequenceNumbers = items
                .map(item => parseInt(item.sequence_no))
                .filter(number => !isNaN(number)) // Geçersiz (NaN) değerleri filtrele
                .sort((a, b) => a - b);

            const missingNumbers = [];
            const sequenceErrors = [];

            // Grup içinde eksik sıra numaralarını ve sıralama hatalarını bul
            for (let i = 0; i < sortedSequenceNumbers.length - 1; i++) {
                const currentNum = sortedSequenceNumbers[i];
                const nextNum = sortedSequenceNumbers[i + 1];
                // Ardışık olmayan sıra numarası varsa eksikleri bul
                if (currentNum + 1 !== nextNum) {
                    for (let missing = currentNum + 1; missing < nextNum; missing++) {
                        missingNumbers.push(missing);
                    }
                }
                // Sıralama hatası kontrolü
                if (currentNum >= nextNum) {
                    sequenceErrors.push(`Beklenen sayı ${currentNum}'den büyük olmalıydı, ancak ${nextNum} bulundu`);
                }

            }

            // Sadece hata varsa dön
            if (missingNumbers.length > 0 || sequenceErrors.length > 0) {
                errors[section] = {missingNumbers, sequenceErrors};
            }
        }

        return errors;
    };


    const toggleNodesVisibility = () => {
        setIsNodesVisible(!isNodesVisible);
    };

    const showReportModal = () => {
        setShowReport(true);
    }

    const handleCloseReportModal = () => {
        setShowReport(false);
    }

    const sequenceErrors = checkSequence(formData.listOfItems);
    const toggleDocumentVisibility = () => {
        setShowDocument(!showDocument);
    };
    const refreshData = () => {
        if (location.pathname === '/showResult') {
            // Sayfa zaten '/showResult' ise sayfayı yenile
            window.location.reload();
        } else {
            // Değilse, '/showResult' sayfasına git
            navigate('/showResult');
        }
    }

    const updateInstitution = async (institutionData) => {
        setIsButtonDisabled(true);
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alıyoruz
        try {
            const response = await axios.post(`${apiBaseUrl}/createInstitutionMatch`, institutionData, {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekliyoruz
                }
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                institutionId: response.data.institutionId,
                institutionNameInSB: response.data.institutionNameInSB, ...(response.data.institutionNameInNotice ? {institutionNameInNotice: response.data.institutionNameInNotice} : {})
            }));
            showInformation('Kurum bilgisi başarıyla güncellendi!');

        } catch (error) {
            if (error.response?.status === 403) {
                showWarning(`${error.response.data?.detail}`);
            } else if (error.response?.status === 400) {
                showWarning(`${error.response.data?.detail}`);
            } else {
                showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    }

// Seçilen veya değiştirilen kurum bilgisi ile form verilerini güncelleme
    const handleInstitutionSelectOrChange = async (selectedInstitution) => {

        if (selectedInstitution && selectedInstitution.institutionNameInSB) {
            await updateInstitution({
                institutionId: selectedInstitution.institutionId,
                institution_name: formData.institutionName,
                institution_name_in_sb: selectedInstitution.institutionNameInSB
            });
        }
    };

    const updateInstitutionNameInNotice = async () => {
        if (formData.institutionNameInSB) {
            await updateInstitution({
                institution_name: formData.institutionName,
                institution_name_in_sb: formData.institutionNameInSB,
                institution_name_in_notice: formData.institutionNameInNotice
            });
        } else {
            showWarning('Kurum adı eşleşmesi bulunamadı. Lütfen önce kurum adını eşleştirin.');
        }
    }

    const updateInstitutionNameInNoticeLocal = () => {
        if (formData.tenderAnnouncement) {
            const firstParagraph = formData.tenderAnnouncement.split(/\n\s*\n/)[0];

            if (formData.institutionNameInNotice !== firstParagraph) {
                setFormData({...formData, institutionNameInNotice: firstParagraph});
            }
        }
    };

    const updateTenderAnnouncementLocal = () => {
        if (formData.tenderAnnouncement && formData.institutionNameInNotice) {
            const firstParagraph = formData.tenderAnnouncement.split(/\n\s*\n/)[0];

            if (formData.institutionNameInNotice !== firstParagraph) {
                const newAnnouncement = formData.tenderAnnouncement.replace(firstParagraph, formData.institutionNameInNotice);
                setFormData({...formData, tenderAnnouncement: newAnnouncement});
            }
        }
    };

    useEffect(() => {
        updateInstitutionNameInNoticeLocal();
    }, [formData.tenderAnnouncement]);

    useEffect(() => {
        updateTenderAnnouncementLocal();
    }, [formData.institutionNameInNotice]);

    const sendResultToSB = async (e) => {
        // Kurum eşlemesi olup olmadığını kontrol edin
        if (!formData.institutionId) {
            showWarning('Kurum adı eşleşmesi bulunamadı. Lütfen önce kurum adını eşleştirin.');
            return;
        }

        // Butonu devre dışı bırakın
        setIsLoading(true);

        // Güncellenmiş tender objesi oluşturma
        const updatedTender = {...formData};
        delete updatedTender.id;
        delete updatedTender.nodes;
        delete updatedTender.jobId;
        delete updatedTender.institutionNameInNotice;
        delete updatedTender.institutionNameInSB;
        delete updatedTender.institutionId;
        delete updatedTender.institutionIdInSB;
        delete updatedTender.status;

        // HTML içeriği oluşturma
        const htmlContent = handleSubmit(e);

        // Kurum ID'sini alın
        const institutionId = formData.institutionId;

        // Token'ı yerel depolamadan alın
        const token = localStorage.getItem('token');

        try {
            // API isteğini yapın
            const response = await axios.post(`${apiBaseUrl}/sendResultToSB`, {
                resultId: formData.id,
                updatedTender,
                htmlContent,
                institutionId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Authorization header'ına token'ı ekleyin
                    "Content-Type": "application/json"
                }
            });
            // Başarılı ise bilgi mesajı göster
            if (response.status === 200) {
                    showInformation('Sonuç başarıyla SB\'ye gönderildi!');
                    setFormData(prevFormData => ({...prevFormData, status: 'success'}));
            } else {
                // API isteği başarılı değilse hata fırlatın
                throw new Error(`API isteği başarısız oldu: ${response.statusText}`);
            }
        } catch (error) {
            console.error('API Hatası:', error);


            if (error.response) {
                if (error.response.status === 403) {
                    showWarning(`${error.response.data.detail}`);
                }
               else if (error.response.data && error.response.data.detail) {
                    showError(`API Hatası: ${error.response.data.detail}`);
                } else {
                    showError(`API Hatası: ${error.response.statusText}`);
                }
            } else if (error.request) {
                showError('API\'ye ulaşılamadı. Lütfen internet bağlantınızı kontrol edin.');
            } else {
                showError('Bilinmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
            }
        } finally {
            // Butonu tekrar etkinleştirin
            setIsLoading(false);
        }
    };

    const getStatus = () => {
        if (formData.status === 'success') {
            return <div className="bg-green-500 text-white p-2 rounded">Gönderildi</div>;
        } else if (formData.status === 'seen') {
            return <div className="bg-blue-300 text-white p-2 rounded">Görüldü</div>;
        } else {
            return '';
        }
    };




    return (<div className={`${isNodesVisible ? "" : "container"} mx-auto px-4`}>
        <ReportIssueModal show={showReport} onClose={handleCloseReportModal} resultId={formData.id}/>
        <div className="flex flex-col sm:flex-row text-center w-full items-center">

            <h2 className="font-semibold w-full sm:w-5/6 md:w-11/12 mb-2 sm:mb-0">{formData.jobId}</h2>
            {getStatus()}

            <button onClick={refreshData} title={"Yeni Sonuç Getir"}
                    className="flex items-center justify-center p-2 mb-2 mr-2 text-green-500 ">
                <ArrowPathIcon className="w-6 h-6 mr-2"/>
            </button>
            <button onClick={showReportModal}
                    title={"Sorun Bildir"}
                    className="flex items-center justify-center p-2 mb-2 mr-2 text-red-500 ">
                <ExclamationCircleIcon className="w-6 h-6 mr-2"/>
            </button>
        </div>

        <div className="flex flex-col md:flex-row text-sm md:h-[80vh]">

            {isNodesVisible && (<div
                className={`w-full bg-white shadow ${isNodesVisible ? "md:w-1/2" : "w-0"} overflow-hidden transition-all duration-300 ease-in-out flex flex-col`}>

                {!showDocument ? <textarea
                    name="nodes"
                    placeholder="İçerik"
                    value={formData.nodes}
                    onChange={handleInputChange}
                    className="mr-2 p-2 overflow-ellipsis transition rounded w-full resize-none"
                    style={{flexGrow: 1}}
                /> : <DocumentViewer resultId={formData.id} fileName={formData.jobId}/>}
                <div className="self-end p-2 flex flex-row">

                    <div className="flex justify-center mt-5">
                        <button
                            className={"mr-4 mb-2 px-4 py-2 bg-amber-400 text-white rounded hover:bg-amber-500"}
                            onClick={toggleDocumentVisibility}>{showDocument ? 'İçerik' : 'Belge'}
                        </button>
                    </div>
                    <DownloadFile resultId={formData.id} fileName={formData.jobId}/>
                </div>

            </div>)}

            <button onClick={toggleNodesVisibility} className="p-2 text-gray-900 rounded mb-2 flex items-center">
                {isNodesVisible ? <ChevronLeftIcon className="h-5 w-5"/> :
                    <ChevronRightIcon className="h-6 w-6 m-1 hover:h-8 hover:w-8 hover:m-0"/>}

            </button>
            <div
                className={`w-full ${isNodesVisible ? "md:w-1/2" : "md:w-full"} p-2 bg-white shadow rounded transition-all duration-300 ease-in-out`}>
                <form onSubmit={handleSubmit}
                      className="mr-2 p-2 overflow-ellipsis transition rounded w-full resize-none">
                    {/* Form içeriği */}
                    <div className="mb-4">
                        <div className="flex mb-2">
                            <button type="button" onClick={() => setActiveTab('tab1')}
                                    className={`flex-1 mr-2 py-2 ${activeTab === 'tab1' ? 'bg-blue-500 text-white' : 'bg-amber-200 text-gray-700'}`}>Genel
                                Bilgiler
                            </button>
                            <button type="button" onClick={() => setActiveTab('tab2')}
                                    className={`flex-1 mr-2 py-2 ${activeTab === 'tab2' ? 'bg-blue-500 text-white' : 'bg-amber-200 text-gray-700'}`}>İhale
                                Detayları
                            </button>
                            <button type="button" onClick={() => setActiveTab('tab3')}
                                    className={`flex-1 mr-2 py-2 ${activeTab === 'tab3' ? 'bg-blue-500 text-white' : 'bg-amber-200 text-gray-700'}`}>İhale
                                Metni ve Liste
                            </button>
                            <button type="button" onClick={() => setActiveTab('tab4')}
                                    className={`flex-1 py-2 ${activeTab === 'tab4' ? 'bg-blue-500 text-white' : 'bg-amber-200 text-gray-700'}`}>Özet
                            </button>
                        </div>
                        {activeTab === 'tab1' && (<div>
                            <div className={"flex flex-row"}>
                                <div className={"flex flex-col w-full"}>
                                    <div className={"flex flex-row"}>
                                        <h2 className="font-semibold mb-2  w-1/6">Kurum Adı</h2>
                                        <input type="text" name="institutionName" placeholder="Kurum Adı"
                                               value={formData.institutionName} onChange={handleInputChange}
                                               disabled={true}
                                               className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <h2 className="font-semibold mb-2  w-1/6">Sağlıkbank Kurum Adı</h2>
                                        <div className="mr-2 rounded-sm mb-2  w-5/6">
                                            <InstitutionSearch
                                                apiUrl="institutions"
                                                debounceTime={300}
                                                onInstitutionSelect={handleInstitutionSelectOrChange}
                                                isClearable={false}
                                                selectedId={formData.institutionId}
                                            />
                                        </div>

                                    </div>
                                    <div className="flex flex-row items-center">
                                        <h2 className="font-semibold mb-2 w-1/6">İlan Başlığı</h2>
                                        <div
                                            className="relative mr-2 p-2 border border-gray-300 rounded mb-2 w-5/6">
                                                    <textarea name="institutionNameInNotice" placeholder="Kurum Adı"
                                                              rows={6}
                                                              value={formData.institutionNameInNotice}
                                                              onChange={handleInputChange}
                                                              className="form-textarea w-full focus:outline-none"
                                                    />
                                            <button
                                                type="button"
                                                onClick={updateInstitutionNameInNotice}
                                                disabled={isButtonDisabled}
                                                className={`absolute inset-y-0 right-0 flex items-center rounded-sm justify-center p-2 h-1/4 text-white bg-blue-500  hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 transition duration-300 ease-in-out ${isButtonDisabled ? 'opacity-50' : ''}`}
                                            >
                                                Güncelle
                                            </button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İhale Konusu</h2>
                                <input type="text" name="subjectOfTender" placeholder="İhale Konusu"
                                       value={formData.subjectOfTender} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İhale No</h2>
                                <input type="text" name="tenderNumber" placeholder="İhale No"
                                       value={formData.tenderNumber} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İhale Tarihi</h2>
                                <input type="text" name="offerDate" placeholder="İhale Tarihi"
                                       value={formData.offerDate} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İhale Saati</h2>
                                <input type="text" name="offerTime" placeholder="İhale Saati"
                                       value={formData.offerTime} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İhale Türü</h2>
                                <input type="text" name="tenderType" placeholder="İhale Türü"
                                       value={formData.tenderType} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Kurum Telefonu</h2>
                                <input type="text" name="institutionPhone" placeholder="Kurum Telefonu"
                                       value={formData.institutionPhone} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Kurum Faksı</h2>
                                <input type="text" name="institutionFax" placeholder="Kurum Faksı"
                                       value={formData.institutionFax} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Kurum E-Posta</h2>
                                <input type="text" name="institutionEmail" placeholder="Kurum E-Posta"
                                       value={formData.institutionEmail} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Kurum Adresi</h2>
                                <input type="text" name="institutionAddress" placeholder="Kurum Adresi"
                                       value={formData.institutionAddress} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded mb-2  w-5/6"/>
                            </div>
                        </div>)}
                        {activeTab === 'tab2' && (<div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Teklifin Verileceği Yer</h2>
                                <input type="text" name="submissionsPlace" placeholder="Teklifin Verileceği Yer"
                                       value={formData.submissionsPlace} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Teslim Yeri</h2>
                                <input type="text" name="deliveryPlace" placeholder="Teslim Yeri"
                                       value={formData.deliveryPlace} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">İşin Süresi</h2>
                                <input type="text" name="deliveryDuration" placeholder="İşin Süresi"
                                       value={formData.deliveryDuration} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Teslim Tarihi</h2>
                                <input type="text" name="deliveryDate" placeholder="Teslim Tarihi"
                                       value={formData.deliveryDate} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Proje Başlangıç Tarihi</h2>
                                <input type="text" name="projectStartDate" placeholder="Proje Başlangıç Tarihi"
                                       value={formData.projectStartDate} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">Yurt İçi İstisna Var Mı?</h2>
                                <input type="text" name="domesticAdvantageExists"
                                       placeholder="Yurt İçi İstisna Var Mı?"
                                       value={formData.domesticAdvantageExists} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">% Yurt İçi İstisna</h2>
                                <input type="text" name="domesticAdvantageRate" placeholder="% Yurt İçi İstisna"
                                       value={formData.domesticAdvantageRate} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row"}>
                                <h2 className="font-semibold mb-2  w-1/6">E-İhale Var Mı?</h2>
                                <input type="text" name="isE_Tender" placeholder="E-İhale Var Mı?"
                                       value={formData.isE_Tender} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row mb-2"}>
                                <h2 className="font-semibold mb-2  w-1/6">E-Teklif Var Mı?</h2>
                                <input type="text" name="isE_Offer" placeholder="E-Teklif Var Mı?"
                                       value={formData.isE_Offer} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row mb-2"}>
                                <h2 className="font-semibold mb-2  w-1/6">E-İndirim Var Mı?</h2>
                                <input type="text" name="isE_Reduction" placeholder="E-İndirim Var Mı?"
                                       value={formData.isE_Reduction} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>
                            <div className={"flex flex-row mb-2"}>
                                <h2 className="font-semibold mb-2  w-1/6">Kısmi Teklif Verilebilir Mi?</h2>
                                <input type="text" name="partialOffersAllowed"
                                       placeholder="Kısmi Teklif Verilebilir Mi?"
                                       value={formData.partialOffersAllowed} onChange={handleInputChange}
                                       className="mr-2 p-2 border border-gray-300 rounded w-5/6 mb-2"/>
                            </div>


                            {/* ... other fields for tab 2 */}
                        </div>)}
                        {activeTab === 'tab3' && (<div className="h-[65vh] border ">
                            <h2 className="font-semibold mb-2">İhale Duyurusu</h2>
                            <textarea name="tenderAnnouncement" placeholder="İhale Duyurusu"
                                      value={formData.tenderAnnouncement} onChange={handleInputChange}
                                      className="mr-2 p-2 border border-gray-300 rounded w-full mb-2 h-[30vh]"/>
                            <div className="mb-4 font-normal overflow-auto h-[30vh]">

                                {Object.entries(groupedItems).map(([section, items], sectionIndex) => (
                                    <div key={sectionIndex}>
                                        {section && section !== 'null' &&
                                            <h3 className="font-bold my-4">{section || 'Belirtilmemiş Bölüm'}</h3>}
                                        <table className="min-w-full table-auto border ">
                                            <thead className={"table-header"}>
                                            <tr>
                                                <th className="w-1/12 border">Sıra No</th>
                                                <th className="w-6/12 border">Cins</th>
                                                <th className="w-1/12 border">Miktar</th>
                                                <th className="w-1/12 border">Birim</th>
                                                <th className="w-1/12 border">Açıklama</th>
                                                <th className="w-1/12 border">İşlem</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {items.map((item, index) => (<tr key={index}>
                                                <td className="border text-center">{item.sequence_no ? item.sequence_no : index + 1}</td>
                                                <td className="border">
                                                                <textarea
                                                                    name="item"
                                                                    placeholder="Cins"
                                                                    value={item.item}
                                                                    onChange={(e) => handleItemChange(item.id, e)}
                                                                    className="w-full resize-none overflow-hidden focus:outline-none"
                                                                    onInput={(e) => {
                                                                        handleItemChange(item.id, e);
                                                                        adjustTextareaHeight();
                                                                    }}
                                                                />
                                                </td>
                                                <td className="border">
                                                    <input type="text" name="amount" placeholder="Miktar"
                                                           value={item.amount}
                                                           onChange={(e) => handleItemChange(item.id, e)}
                                                           className="w-full focus:outline-none"/>
                                                </td>
                                                <td className="border">
                                                    <input type="text" name="unit" placeholder="Birim"
                                                           value={item.unit}
                                                           onChange={(e) => handleItemChange(item.id, e)}
                                                           className="rounded w-full focus:outline-none"/>
                                                </td>
                                                <td className="border">
                                                                <textarea
                                                                    name="explanation"
                                                                    placeholder="Açıklama"
                                                                    value={item.explanation}
                                                                    className="w-full resize-none overflow-hidden focus:outline-none"
                                                                    onInput={(e) => {
                                                                        handleItemChange(item.id, e);
                                                                        adjustTextareaHeight();
                                                                    }}
                                                                />
                                                </td>
                                                <td className="border text-center">
                                                    <button
                                                        type="button"
                                                        onClick={() => removeItem(item.id)}
                                                        className="text-red-500 hover:text-red-700 px-2">
                                                        <MinusIcon className="h-5 w-5" aria-hidden="true"/>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => addItem(item.section, item.sequence_no)}
                                                        className="text-green-500 hover:text-green-700 px-2">
                                                        <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                                    </button>
                                                </td>

                                            </tr>))}
                                            </tbody>
                                        </table>
                                    </div>))}

                            </div>
                            <div>
                                {Object.keys(sequenceErrors).length > 0 && (
                                    <div className="text-red-500 font-bold">
                                        Sıra numaralarında eksiklik veya sıralama hatası olabilir!
                                        {Object.entries(sequenceErrors).map(([section, details]) => (
                                            <div key={section}>
                                                <strong>{section}</strong>
                                                {details.missingNumbers.length > 0 && (
                                                    <span> Eksik Sıralar: {details.missingNumbers.join(', ')}</span>)}
                                                {details.sequenceErrors.length > 0 && (<div> Sıralama
                                                    Hataları: {details.sequenceErrors.join(', ')}.</div>)}
                                            </div>))}
                                    </div>)}
                            </div>

                        </div>)}
                        {activeTab === 'tab4' && (<div>
                                <textarea name="tenderSummary" placeholder="Özet" value={formData.tenderSummary}
                                          onChange={handleInputChange}
                                          className="mr-2 p-2 border border-gray-300 rounded w-full h-full min-h-[65vh]"/>
                        </div>)}
                    </div>

                </form>
                <div className="text-end mt-2">
                    <button
                        type="submit"
                        className="mr-4 mb-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Kopyala'}
                    </button>

                    <button
                        type="submit"
                        className="mr-4 mb-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                        onClick={sendResultToSB}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Gönder'}
                    </button>
                </div>
            </div>
        </div>
    </div>);
};

export default Result;
