import React, {useState, useEffect} from 'react';
import InstitutionSearch from "./InstitutionSearch";
import axios from "axios";
import {apiBaseUrl} from "../consts";
import {useOutletContext} from "react-router-dom";
import TenderInstitutions from "./TenderInstitutions";

function Institutions() {
    const [formData, setFormData] = useState({
        institutionId: '',
        institutionName: '',
        institutionNameInSB: '',
        institutionNameInNotice: ''
    });
    const [institutionMatches, setInstitutionMatches] = useState([]);
    const {showError, showWarning, showInformation} = useOutletContext();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        if (formData.institutionId) {
            fetchInstitutionMatches(formData.institutionId);
        }
    }, [formData.institutionId]);

    const fetchInstitutionMatches = async (institutionId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${apiBaseUrl}/institutionMatches/${institutionId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setInstitutionMatches(response.data);
        } catch (error) {
            showError('Kurum eşleşmeleri alınırken bir hata oluştu');
        }
    };

    const deleteInstitutionMatch = async (institutionName) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${apiBaseUrl}/deleteInstitutionMatch`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    institution_name: institutionName
                }
            });
            setInstitutionMatches(prevMatches => prevMatches.filter(match => match.institutionName !== institutionName));
            showInformation('Kurum eşleşmesi başarıyla silindi!');
        } catch (error) {
            if (error.response?.status === 403) {
                showWarning(`${error.response.data?.detail}`);
            } else if (error.response?.status === 404) {
                showWarning(`${error.response.data?.detail}`);
            } else {
                showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
            }
        }
    };

    const deleteInstitution = async () => {
        setIsButtonDisabled(true);
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${apiBaseUrl}/deleteInstitution`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    institution_id: formData.institutionId
                }
            });
            setFormData({institutionId: '', institutionName: '', institutionNameInSB: '', institutionNameInNotice: ''});
            setInstitutionMatches([]);
            showInformation('Kurum başarıyla silindi!');
        } catch (error) {
            if (error.response?.status === 403) {
                showWarning(`${error.response.data?.detail}`);
            } else if (error.response?.status === 404) {
                showWarning(`${error.response.data?.detail}`);
            } else {
                showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const updateInstitution = async () => {
        setIsButtonDisabled(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${apiBaseUrl}/createInstitution`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            showInformation('Kurum bilgisi başarıyla güncellendi!');
        } catch (error) {
            if (error.response?.status === 403) {
                showWarning(`${error.response.data?.detail}`);
            } else if (error.response?.status === 400) {
                showWarning(`${error.response.data?.detail}`);
            } else {
                showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const handleInstitutionSelectOrChange = async (selectedInstitution) => {
        setFormData({
            institutionId: selectedInstitution.institutionId,
            institutionName: selectedInstitution.institutionName,
            institutionNameInSB: selectedInstitution.institutionNameInSB,
            institutionNameInNotice: selectedInstitution.institutionNameInNotice
        });
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const onClear = () => {
        setFormData({
            institutionId: '',
            institutionName: '',
            institutionNameInSB: '',
            institutionNameInNotice: ''
        })
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4 text-center text-gray-800">Kurum Yönetimi</h1>
            <InstitutionSearch
                apiUrl="institutions"
                debounceTime={300}
                placeholder={formData?.institutionNameInSB ? formData.institutionNameInSB : 'Kurum Seç'}
                onInstitutionSelect={handleInstitutionSelectOrChange}
                className="mb-4"
                onClear={onClear}
            />
            {formData.institutionNameInSB ?
                <div className="mb-4 p-4 bg-gray-50 rounded-lg shadow-md">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white border border-gray-300 rounded-lg">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Kurum Adı (SağlıkBank)
                            </th>
                            <th className="px-6 py-3 bg-gray-50 border-b text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Kurum Adı (İlan)
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap border-b">
                                <input
                                    type={"text"}
                                    name="institutionNameInSB"
                                    value={formData.institutionNameInSB}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap border-b">
                                <textarea
                                    rows={6}
                                    name="institutionNameInNotice"
                                    value={formData.institutionNameInNotice}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <button
                    onClick={updateInstitution}
                    disabled={isButtonDisabled}
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Güncelle
                </button>
                <button
                onClick={deleteInstitution}
             disabled={isButtonDisabled}
             className="px-4 mx-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
            Sil
        </button>
    {
        institutionMatches.length > 0 && (
            <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                <h2 className="text-xl font-semibold mb-2 text-gray-700">Kurum Eşleşmeleri</h2>
                <ul className="list-disc pl-5 text-gray-600">
                    {institutionMatches.map(match => (
                        <li key={match.institutionId} className="flex justify-between items-center mb-1">
                            {match.institutionName}
                            <button
                                onClick={() => deleteInstitutionMatch(match.institutionName)}
                                className="ml-4 px-2 py-1 text-red-600 border border-red-600 rounded hover:bg-red-600 hover:text-white transition-colors duration-200"
                            >
                                Sil
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
</div>: <TenderInstitutions />}
</div>
)
    ;
}

export default Institutions;
