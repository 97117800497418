import {Outlet, useLocation} from 'react-router-dom';
import Layout from "./Layout";
import {useState} from "react";
import ErrorModal from "./ErrorModal";
import WarningModal from "./WarningModal";
import InfoModal from "./InfoModal";

const LayoutRoute = ({ children }) => {
    const [showErr, setShowErr] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showWarn, setShowWarn] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');

    const location = useLocation();

    const showError = (message) => {
        setErrorMessage(message);
        setShowErr(true);
    };

    const handleCloseError = () => {
        setShowErr(false);
    };

    const showWarning = (message) => {
        setWarningMessage(message);
        setShowWarn(true);
    };

    const handleCloseWarning = () => {
        setShowWarn(false);
    };

    const showInformation = (message) => {
        setInfoMessage(message);
        setShowInfo(true);
    }

    const handleCloseInformation = () => {
        setShowInfo(false);
    }

    return (
        <Layout showError={showError} showWarning={showWarning} showInformation={showInformation}>
            <Outlet context={{ showError, showWarning, showInformation }} />
            {/* Error, Warning ve Info Modalları */}
            <ErrorModal show={showErr} message={errorMessage} onClose={handleCloseError} />
            <WarningModal show={showWarn} message={warningMessage} onClose={handleCloseWarning} />
            <InfoModal show={showInfo} message={infoMessage} onClose={handleCloseInformation} />
        </Layout>
    );
};

export default LayoutRoute;
