import React, {useEffect, useRef, useState} from 'react';
import {useNavbar} from "./useNavbar";
import {Link} from 'react-router-dom';

const Navbar = () => {
    const navbar = useNavbar();
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false); // "user" için dropdown açılıp kapanmasını kontrol et
    const dropdownRef = useRef(null); // Açılır menü için bir ref oluştur
    const menudownRef = useRef(null); // Açılır menü için bir ref oluştur

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false); // Eğer dışarı tıklanırsa menüyü kapat
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Temizlik
        };
    }, [dropdownRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menudownRef.current && !menudownRef.current.contains(event.target)) {
                setMenuOpen(false); // Eğer dışarı tıklanırsa menüyü kapat
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside); // Temizlik
        };
    }, [menudownRef]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen); // Dropdown'ı aç/kapa
    };

    const signOut = () => {
        localStorage.removeItem('token'); // Token'ı sil
        localStorage.removeItem('userName'); // Kullanıcı bilgilerini sil
        window.location.reload(); // Sayfayı yenile
    };

    const navItems = () => {
        return Object.values(navbar).filter(item => item.id !== 'logo' && item.id !== 'user' && item.text).map((item, index) => (
            <li key={index} className="px-4 py-2 hover:bg-gray-100">
                <Link to={item.url} className="hover:text-gray-800" onClick={toggleMenu}>
                    {item.text}
                </Link>
            </li>
        ));
    };

    return (
        <nav className="fixed z-10 w-full bg-gray-50 shadow">
            <div className="flex justify-between p-4 items-center">
                {/* Logo */}
                <div className="flex items-center">
                    <Link to="/" className="h-12">
                        <img src={navbar.logo} alt="Logo" className="h-full object-contain"/>
                    </Link>
                    {/* Desktop View Navbar Items */}
                    <ul className="hidden md:flex space-x-4 ml-4">
                        {navItems()}
                    </ul>
                </div>

                {/* Right Section for User Dropdown, Sign Out, and Mobile Menu Button */}
                <div className="flex items-center">
                    {localStorage.getItem('token') && (
                        <div className="relative hidden md:flex" ref={dropdownRef}>
                            {/* User Dropdown Toggle */}
                            <button onClick={toggleDropdown}
                                    className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded">
                                {localStorage.getItem('userName')}
                            </button>
                            {/* User Dropdown Menu */}
                            {dropdownOpen && (
                                <ul className="absolute right-0 mt-2 bg-white w-48 shadow-lg rounded">
                                    <li className="px-4 py-2 hover:bg-gray-100"><Link to="/settings"
                                                                                      onClick={toggleMenu}>Ayarlar</Link>
                                    </li>
                                    <li className="px-4 py-2 hover:bg-red-400 cursor-pointer" onClick={signOut}>Çıkış
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}

                    {/* Mobile Menu Button - Always visible */}
                    <div className="md:hidden">
                        <button
                            className="text-gray-900 hover:text-gray-600 p-2"
                            onClick={() => toggleMenu()}
                        >
                            ☰
                        </button>
                    </div>
                </div>

                {/* Mobile View Navbar Items */}
                {menuOpen && (
                    <div className="absolute top-full right-0 bg-white w-full shadow-lg md:hidden" ref={menudownRef}>
                        <ul>
                            {navItems()}
                            {localStorage.getItem('token') && (
                                <>
                                    <li className="px-4 py-2 hover:bg-gray-100"><Link to="/settings"
                                                                                      onClick={toggleMenu}>Ayarlar</Link>
                                    </li>
                                    <li className="px-4 py-2 hover:bg-red-400 cursor-pointer" onClick={signOut}>Çıkış
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </nav>

    );
};

export default Navbar;
