import React, {useEffect, useState} from 'react';
import axios from "axios";
import {apiBaseUrl} from "../consts";
import {useOutletContext} from "react-router-dom";
import ClearFailedFiles from "./ClearFailedFiles";

const FileStatusList = (props) => {
    const [files, setFiles] = useState([]);
    const {showError, showInformation} = useOutletContext();

    // Function to fetch file statuses
    const fetchFileStatuses = async () => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
            const response = await axios.get(apiBaseUrl + '/getFileStatuses', {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                }
            });
            setFiles(response.data);
        } catch (error) {
            showError('Liste getirilirken Hata Oluştu:', error);
        }
    };

    // Fetch file statuses on component mount and every 5 seconds
    useEffect(() => {
        fetchFileStatuses();
        const interval = setInterval(fetchFileStatuses, 5000);
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    useEffect(() => {
        fetchFileStatuses();
    }, [props.refresh]);

    // Dosya türleri ve ikonları
    const fileTypeIcons = {
        zip: 'public/images/icons/iconmonstr-zip-14.svg',
        rar: 'public/images/icons/iconmonstr-zip-15.svg',
        pdf: 'public/images/icons/iconmonstr-file-34.svg',
        docx: 'public/images/icons/iconmonstr-document-thin.svg',
        doc: 'public/images/icons/iconmonstr-document-thin.svg',
        xlsx: 'public/images/icons/iconmonstr-layout-table-lined.svg',
        rtf: 'public/images/icons/iconmonstr-globe-thin.svg',
        html: 'public/images/icons/iconmonstr-globe-thin.svg'
    };

    // Dosya uzantısını alıp ikon döndür
    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop();
        return fileTypeIcons[extension] || 'images/icons/iconmonstr-document-thin.svg';
    };

    // Dosya adını job_id'den ayıklar
    const extractFileName = (jobId) => {
        const parts = jobId.split('/');
        let fileName = parts.length ? parts[parts.length - 1] : jobId;
        // Sonundaki ')' karakterini sil
        fileName = fileName.replace(/'\)$/g, '');
        return fileName;
    };

    const reorderWork = async (jobId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(apiBaseUrl + '/reorderWork',
                jobId
                , {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            setFiles(files.filter(f => f.id !== jobId).concat(response.data));
            showInformation('İşlem sırası değiştirildi');
        } catch (error) {
            showError('İşlem sırası değiştirilirken hata oluştu:', error);
        }
    }
    const statusPriority = {
        started: 1,
        queued: 2,
        finished: 3,
        failed: 4,
    };

    files.sort((a, b) => statusPriority[a.status] - statusPriority[b.status]);

    return (
        <div className="w-full">
            <div className="flex justify-between items-center p-4">
                <div className="text-center">
                    <h2 className="font-bold text-gray-800">Toplam</h2>
                    <div className="text-sm text-gray-600">{files.length}</div>
                </div>
                <div className="text-center">
                    <h2 className="font-bold text-green-500">Tamamlanan</h2>
                    <div className="text-sm text-green-600">{files.filter(f => f.status === 'finished').length}</div>
                </div>

                <div className="text-center">
                    <h2 className="font-bold text-blue-500">Bekleyen</h2>
                    <div className="text-sm text-blue-600">{files.filter(f => f.status === 'queued').length}</div>
                </div>
                <div className="text-center">
                    <h2 className="font-bold text-yellow-500">İşlenen</h2>
                    <div className="text-sm text-yellow-600">{files.filter(f => f.status === 'started').length}</div>
                </div>
                <div className="text-center">
                    <h2 className="font-bold text-red-500">Başarısız</h2>
                    <div className="text-sm text-red-600">{files.filter(f => f.status === 'failed').length}</div>
                </div>
            </div>


            {files && files.length > 0 ? (
                files.map((file, index) => (
                    <div key={index} className="flex items-center my-2 p-2 border border-rose-100 rounded-md">
                        <img src={getFileIcon(file.description)} alt="file-icon" width={40} height={40}/>
                        <div className="ml-4 flex-grow">
                            <p className="truncate">{extractFileName(file.description)}</p>
                            {/* Render the date if it's available in your file object */}
                            {/* <p className="text-xs text-gray-600">{new Date(file.date).toLocaleString()}</p> */}
                        </div>
                        {file.status === 'failed' && (
                            <span className="px-2 py-1 text-sm rounded-full bg-red-200 text-red-700">
                        <button onClick={() => reorderWork(file.id)} disabled={file.status === 'started'}
                                className="px-2 py-1 text-sm rounded-full bg-red-200 text-red-700">
                            Tekrar Dene
                        </button>
                    </span>
                        )}
                        {file.status === 'started' && (
                            <span
                                className="px-2 py-1 text-sm rounded-full bg-yellow-200 text-yellow-700 flex items-center">
                        <div
                            className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-yellow-500 mr-2"></div>
                        İşleniyor
                    </span>
                        )}
                        {file.status === 'finished' && (
                            <span className="px-2 py-1 text-sm rounded-full bg-green-200 text-green-700">
                        Tamamlandı
                    </span>
                        )}
                        {file.status === 'queued' && (
                            <span className="px-2 py-1 text-sm rounded-full bg-blue-200 text-blue-700">
                        Sıraya Alındı
                    </span>
                        )}
                    </div>
                ))
            ) : (
                <div className="m-4 flex-grow justify-center">
                    <p>İşlenen Dosya Yok</p>
                </div>
            )}
            {files && files.length > 0 && files.find(f=>f.status==='failed')&& <ClearFailedFiles/>}
        </div>

    );
};

export default FileStatusList;
