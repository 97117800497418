import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import FileUploaderPage from "./components/FileUploaderPage";
import ResultsList from "./components/ResultsList";
import Result from "./components/Result";
import SignIn from "./components/SignIn";
import useTokenValidation from "./hooks/useTokenValidation";
import LayoutRoute from "./components/LayoutRoute";
import SettingsPage from "./components/SettingsPage";
import Institutions from "./components/Institutions";

function App() {
    const navigate = useNavigate();
    const [tokenUpdated, setTokenUpdated] = useState(0);
    const isValidToken = useTokenValidation(tokenUpdated);
    const [loading, setLoading] = useState(true);
    const onSignIn = () => {
        setTokenUpdated(prev => prev + 1); // tokenUpdated'ı güncelleyerek hook'u tetikle
    };

    useEffect(() => {
        if (isValidToken !== null) {
            setLoading(false);
            if (isValidToken === false && window.location.pathname !== '/signIn') {
                navigate('/signIn');
            } else if (isValidToken && window.location.pathname === '/signIn') {
                navigate('/fileUpload');
            }
        }
    }, [isValidToken, navigate]);

    if (loading) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <Routes>
            <Route element={<LayoutRoute />}>
                <Route path="/" element={<ResultsList />} />
                <Route path="/fileUpload" element={<FileUploaderPage />} />
                <Route path="/showResult/:result_id" element={<Result />} />
                <Route path="/showResult" element={<Result />} />
                <Route path="/signIn" element={<SignIn onSignIn={onSignIn} />} />
                <Route path={'/settings'} element={<SettingsPage/>} />
                <Route path={'/institutions'} element={<Institutions/>} />
                {/* Diğer rotalarınız */}
            </Route>
        </Routes>
    );
}

export default App;
