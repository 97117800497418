import React, { useState, useEffect } from "react";
import axios from 'axios';
import { apiBaseUrl } from "../consts";

const DocumentViewer = ({ resultId }) => {
    const [documentUrl, setDocumentUrl] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const { blob } = await downloadFile(resultId);
                const fileUrl = URL.createObjectURL(blob);
                setDocumentUrl(fileUrl);
            } catch (error) {
                setError("Dosya yüklenirken bir hata oluştu.");
            }
        };

        fetchDocument();

        return () => {
            if (documentUrl) {
                URL.revokeObjectURL(documentUrl); // Temizleme işlemi
            }
        };
    }, [resultId]);

    if (error) {
        return (
            <div className="w-full h-screen bg-gray-100 flex justify-center items-center">
                <div className="flex items-center justify-center w-full h-full bg-white shadow-lg overflow-hidden">
                    <div>{error}</div>
                </div>
            </div>
        );
    }

    if (!documentUrl) {
        return (
            <div className="w-full h-screen bg-gray-100 flex justify-center items-center">
                <div className="flex items-center justify-center w-full h-full bg-white shadow-lg overflow-hidden">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full h-screen bg-gray-100 flex justify-center items-center">
            <div className="w-full h-full bg-white shadow-lg overflow-hidden">
                <iframe id={"document"} title={"document"} src={documentUrl} frameBorder="0" style={{ width: '100%', height: '100%' }} allowFullScreen></iframe>
            </div>
        </div>
    );
};

export default DocumentViewer;

// Dosyayı indirip blob olarak döndüren fonksiyon
const downloadFile = async (resultId) => {
    try {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
        const response = await axios.get(`${apiBaseUrl}/convertAndDownload/${resultId}`, {
            responseType: 'blob', // Dosyayı bir blob olarak almak için
            headers: {
                Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekle
            }
        });

        return { blob: response.data };
    } catch (error) {
        console.error("Dosya indirilirken hata oluştu:", error);
        throw error;
    }
};
