import { useEffect, useState } from "react";
import axios from "axios";
import {apiBaseUrl} from "../consts";

function useTokenValidation(tokenUpdated) {
    const [isValidToken, setIsValidToken] = useState(null);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await axios.get(apiBaseUrl+'/token/validate', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.data.message === "Token is valid") {
                    setIsValidToken(true);

                } else {
                    setIsValidToken(false);

                }
            } catch (error) {
                console.error('Token validation error:', error);
                setIsValidToken(false);
            }
        } else {
            setIsValidToken(false);
        }
    };

    useEffect(() => {
        validateToken(); // İlk yüklemede token'ı doğrula

        const intervalId = setInterval(() => {
            validateToken(); // Belirli aralıklarla token'ı yeniden doğrula
        }, 60000);

        const handleStorageChange = () => {
            validateToken();
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            clearInterval(intervalId); // Komponent temizlendiğinde interval'ı durdur
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [tokenUpdated]); // tokenUpdated değiştiğinde etkilenir


    return isValidToken;
}

export default useTokenValidation;
