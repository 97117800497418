import React, { useEffect } from 'react';

const ErrorModal = ({ show, message, onClose }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                onClose();
            }
        };

        if (show) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white rounded-lg w-11/12 md:max-w-md mx-auto">
                <div className="py-4 text-center">
                    <h2 className="text-2xl font-semibold">Hata</h2>
                    <p className="text-sm text-gray-700 mt-4">{message}</p>
                </div>
                <div className="flex justify-end pb-4 pr-4">
                    <button
                        onClick={onClose}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Kapat
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
