import React, {useState} from 'react';

// Modal ve diğer gerekli komponentlerinizi burada import edin

function ConfirmFilesModal({fileReport, onConfirm, onClose}) {
    const [selectedFiles, setSelectedFiles] = useState(fileReport.confirm_required);

    const handleFileSelection = (filename) => {
        setSelectedFiles(prev =>
            prev.includes(filename) ? prev.filter(file => file !== filename) : [...prev, filename]
        );
    };

    const handleConfirm = () => {
        onConfirm(selectedFiles);
    };
    const handleCancel = () => {
        onConfirm([]);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white rounded-lg w-11/12 md:max-w-md mx-auto">
                <div className="py-4 text-center">
                    <h3 className="text-sm font-semibold text-yellow-600">{fileReport.confirm_required.length} adet
                        dosya daha önce işlenmiş.<br></br> Seçilenleri yeniden yüklemek istiyor musunuz?</h3>
                </div>
                <ul className="text-sm list-disc list-inside bg-whiterounded p-4">
                    {fileReport.confirm_required.map(file => (
                        <li key={file} className="text-gray-700 flex justify-between items-center">
                            <p className={"truncate"}>
                                {file}
                            </p>
                            <input
                                type="checkbox"
                                checked={selectedFiles.includes(file)}
                                onChange={() => handleFileSelection(file)}
                            />
                        </li>
                    ))}
                </ul>
                <div className={"flex flex-row-reverse justify-between"}>
                    <div className="p-4">
                        <button
                            onClick={handleConfirm}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Onayla
                        </button>
                    </div>

                    <div className="p-4">
                        <button
                            onClick={handleCancel}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                            İptal
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmFilesModal;