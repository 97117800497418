import React, {useState} from 'react';
import FileUploader from "./FileUploader";
import FileStatusList from "./FileStatusList";

function FileUploaderPage(props) {

    const [refresh, setRefresh] = useState(false);

    const onUpload = () => {
        setRefresh(!refresh);
    }

    return (
        <div className="container mx-auto w-full flex justify-center">
            <div className="md:w-1/2 w-full flex-col">
            <FileUploader onUpload={onUpload}/>
            <FileStatusList refresh={refresh} />
        </div>
        </div>
    );
}

export default FileUploaderPage;