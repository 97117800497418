import React from 'react';
import Navbar from "./Navbar";
import {useLocation} from "react-router-dom";

const Layout = ({ children }) => {


    const location = useLocation();
    const shouldShowNavbar = location.pathname !== "/signIn"; // /signIn yolunda olmadığında Navbar göster


    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, );
    });

    return (
        <div className={"h-full w-full min-h-screen bg-stone-100"}>
            {shouldShowNavbar && <Navbar />}
            {shouldShowNavbar &&<div className={"h-24 md:h-48 lg:h-24"}/>}
            <div className={"flex flex-col items-center"}>
                <main className={'w-full transition duration-300 ease-in-out transition-bg'}>
                    {childrenWithProps}
                </main>
            </div>
        </div>
    );
};

export default Layout;
