import React, {useCallback, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import {apiBaseUrl} from "../consts";
import {useOutletContext} from "react-router-dom";
import ConfirmFilesModal from "./ConfirmFilesModal";

const FileUploader = (props) => {
    const [fileReport, setFileReport] = useState(null);
    const [isUploading, setIsUploading] = useState(false); // Yükleme durumu için yeni durum değişkeni
    const { showError } = useOutletContext();


    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
        if (isUploading) {
            // Eğer yükleme işlemi devam ediyorsa, kullanıcıya bilgi ver
            showError("Dosya yükleniyor, lütfen işlem tamamlanana kadar bekleyin.");
            return;
        }

        setIsUploading(true); // Yükleme işlemi başladığında durumu güncelle
        // FormData nesnesi oluştur
        const formData = new FormData();

        // Her bir dosyayı FormData nesnesine ekle
        acceptedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            // FastAPI sunucusuna POST isteği yap
            const token = localStorage.getItem('token');
            const response = await axios.post(apiBaseUrl+'/addFiles', formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (rejectedFiles.length > 0) {
                const rejectedFileNames = rejectedFiles.map(file => file.file.name);
                showError(`Kabul edilmeyen dosyalar: ${rejectedFileNames.join(', ')}`);
            }

            // Sunucudan gelen cevabı konsola yazdır
            setFileReport(response.data);
            //sayfa yenileme
            props.onUpload();


        } catch (error) {

            showError(`Dosyalar yüklenemedi: ${error}`);
        } finally {
            setIsUploading(false); // İşlem tamamlandığında durumu güncelle
        }

    }, [isUploading, showError, props]);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
        accept: {
            'application/zip': ['.zip'],
            'application/x-zip-compressed': ['.zip'],
            'application/x-compressed': ['.zip'],
            'multipart/x-zip': ['.zip'],
            'application/x-rar-compressed': ['.rar'],
            'application/rar': ['.rar'],
            'application/x-rar': ['.rar'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/rtf': ['.rtf'],
            'text/html': ['.html', '.htm'],
            'image/jpeg': ['.jpg', '.jpeg']
        },
        // Yükleme sırasında dosya eklemeyi engelle
        noClick: isUploading,
        noKeyboard: isUploading,
        noDrag: isUploading,
        disabled: isUploading

    });

    async function confirmFiles(selectedFiles) {
        const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
        const response = await fetch(apiBaseUrl + '/confirmFiles', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // İçerik tipini belirleyin
                'Authorization': `Bearer ${token}`, // Token'ı Authorization header'ına ekleyin
            },
            body: JSON.stringify(selectedFiles) // Eğer API doğrudan bir liste bekliyorsa
            // Gönderilen verileri JSON formatında ve body içinde yerleştirin
        });

        if (response.ok) {
            // İşlem başarılı, duruma göre UI güncellemesi yap
            setFileReport({...fileReport, confirm_required: []}); // Confirm gereken dosyaları temizle
        } else {
            // Hata yönetimi
            const errorText = await response.json(); // Hata mesajını JSON olarak okuyun
            console.error('Dosya onaylama sırasında hata oluştu: ', errorText);
            showError('Dosya onaylama sırasında hata oluştu');
        }
    }





    return (
        <div className="w-full">
            <div {...getRootProps()} className={`p-10 bg-amber-100 border-dashed border-2 border-gray-300 rounded-md cursor-pointer ${isDragActive ? 'bg-blue-100 border-blue-500' : 'bg-white'}`}>

                <input {...getInputProps()} multiple />
                {
                    isUploading ?
                        <p className="text-center text-rose-700">Dosya yükleniyor, lütfen bekleyin...</p> :
                        isDragActive ?
                            <p className="text-center text-gray-700">Dosyaları buraya bırakın...</p> :
                            <p className="text-center text-gray-700">Dosya yüklemek için buraya sürükleyip bırakın veya tıklayın.</p>
                }
            </div>
            {fileReport && (
                <div className="mt-5">
                    {fileReport.uploaded_files && fileReport.uploaded_files.length > 0 && (
                        <div className="mb-4">
                            <h3 className="text-sm font-semibold text-green-600">Yüklenen Dosyalar:</h3>
                            <h4 className="text-sm text-gray-700">{fileReport.uploaded_files.length} adet</h4>
                            <ul className="text-sm list-disc list-inside bg-white shadow-md rounded p-4">
                                {fileReport.uploaded_files.map(file => <li key={file} className="text-gray-700">{file}</li>)}
                            </ul>
                        </div>
                    )}
                    {fileReport.failed_files && fileReport.failed_files.length > 0 && (
                        <div>
                            <h3 className="text-sm font-semibold text-red-600">Yüklenemeyen Dosyalar:</h3>
                            <h4 className="text-sm text-gray-700">{fileReport.failed_files.length} adet</h4>
                            <ul className="text-sm list-disc list-inside bg-white shadow-md rounded p-4">
                                {fileReport.failed_files.map(file => <li key={file} className="text-gray-700">{file}</li>)}
                            </ul>
                        </div>
                    )}

                    {fileReport.confirm_required && fileReport.confirm_required.length > 0 && (
                        <ConfirmFilesModal fileReport={fileReport} onConfirm={confirmFiles} />

                    )
                    }
                </div>
            )}
        </div>
    );
};

export default FileUploader;
