import React from 'react';
import axios from 'axios';
import {apiBaseUrl} from "../consts";
import {useOutletContext} from "react-router-dom";

function ClearFailedFiles({resultId, fileName}) {
    const { showError, showWarning, showInformation } = useOutletContext();
    const clearFailedFiles = async () => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
            const response = await axios.get(`${apiBaseUrl}/clearFailedJobs`, {
                responseType: 'blob', // Dosyayı bir blob olarak almak için
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekle
                }
            });
            if (response.status === 200) {
                showInformation('Başarısız dosyalar temizlendi');
            }
        } catch (error) {
            if (error.response?.status === 403) {
                showWarning('Bu İşlem İçin Yetkiniz Yok! Lütfen Yöneticinizle İletişime Geçin.');
            }
            else {
            showError('Bir hata oluştu:'+error.message);
        }
        }
    };


    return (
        <div className="flex justify-end mt-5">
            <button
                className="mr-4 mb-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                title="Başarısız dosyaları temizle"
                onClick={() => clearFailedFiles()}>
                Temizle
            </button>
        </div>
    );
}
export default ClearFailedFiles;
