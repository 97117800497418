import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Select from "react-select"; // Get today's date in YYYY-MM-DD format
import {useNavigate, useOutletContext} from 'react-router-dom';
import {apiBaseUrl} from "../consts";
import {ArrowPathIcon, ExclamationCircleIcon, TrashIcon, BuildingLibraryIcon} from "@heroicons/react/16/solid";


const today = new Date().toISOString().split('T')[0];


const ResultsList = () => {
    const [statuses, setStatuses] = useState(JSON.parse(sessionStorage.getItem('statuses')) || ['new', 'failed', "seen", "processing"]);
    const [startDate, setStartDate] = useState(sessionStorage.getItem('startDate') || today);
    const [endDate, setEndDate] = useState(sessionStorage.getItem('endDate') || '');
    const [fileName, setFileName] = useState(sessionStorage.getItem('fileName') || '');
    const [userNames, setUserNames] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(JSON.parse(sessionStorage.getItem('selectedUsers')) || []);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const {showError, showWarning} = useOutletContext();

    useEffect(() => {
        sessionStorage.setItem('statuses', JSON.stringify(statuses));
        sessionStorage.setItem('startDate', startDate);
        sessionStorage.setItem('endDate', endDate);
        sessionStorage.setItem('fileName', fileName);
        sessionStorage.setItem('selectedUsers', JSON.stringify(selectedUsers));
    }, [statuses, startDate, endDate, fileName, selectedUsers]);

    useEffect(() => {
        // Fetch results when the component mounts
        fetchResults();
        fetchUserNames();
    }, []);

    const navigateToShowResult = (resultId) => {
        navigate(`/showResult/${resultId}`); // Use navigate() function
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Form gönderiminin varsayılan davranışını engelle
            fetchResults(); // fetchResults fonksiyonunu çağır
        }
    };

    const fetchUserNames = async () => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
            const response = await axios.get(apiBaseUrl + '/userNames', {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                }
            });
            const formattedUserNames = response.data.map(userName => ({
                value: userName, // Burada kullanıcı adı hem value hem de label olarak kullanılıyor
                label: userName
            }));
            setUserNames(formattedUserNames);
        } catch (error) {
            showError('Liste getirilirken Hata Oluştu:', error);
        }
    }

    const fetchResults = async () => {

        if (statuses.length > 0) {
            setIsLoading(true);
            try {

                const params = new URLSearchParams();
                statuses.forEach(status => params.append('statuses', status)); // Handle multiple statuses
                if (startDate) params.append('start_date', startDate);
                if (endDate) params.append('end_date', endDate);
                if (fileName) params.append('file_name', fileName); // Add file name to query params
                if (selectedUsers && selectedUsers.length > 0) selectedUsers.forEach(user => params.append('user_names', user)); // Add selected users to query params
                const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın

                const response = await axios.get(apiBaseUrl + `/results/?${params.toString()}`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                    }
                });
                setResults(response.data);
            } catch (error) {
                showError('Error fetching results:', error);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        } else {
            showWarning('En az bir durum seçmelisiniz');
        }

    };

    const convertErrorMessages = (error = "") => {
        if (error.includes("No text found for")) {
            return "İçerik oluşturulamadı"
        } else if (error.includes("Request timed out")) {
            return "İşlem zaman aşımına uğradı, lütfen tekrar deneyin."
        } else if (error.includes("Rate limit reached ")) {
            return "Günlük limit doldu, lütfen limiti yükseltin."
        } else if (error.includes("Task exceeded maximum timeout value")) {
            return "Çok uzun süren işlem, lütfen tekrar deneyin."
        } else {
            return "Bilinmeyen bir hata oluştu. Tekrar deneyin."
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 'new':
                return 'bg-green-100 border-green-500';
            case 'seen':
                return 'bg-blue-100 border-blue-500';
            case 'success':
                return 'bg-yellow-100 border-yellow-500';
            case 'failed':
                return 'bg-red-100 border-red-500';
            case 'processing':
                return 'bg-amber-100 border-amber-500';
            default:
                return 'bg-gray-100 border-gray-500';
        }
    };

    const options = [
        {value: 'new', label: 'Yeni'},
        {value: 'seen', label: 'Görüldü'},
        {value: 'success', label: 'Tamamlandı'},
        {value: 'failed', label: 'Hatalı'},
        {value: 'processing', label: 'İşleniyor'}
    ];

    const handleStatusChange = (selectedOptions) => {
        const statuses = selectedOptions.map(option => option.value);
        setStatuses(statuses);
    };

    const handleSelectedUsersChange = (selectedOptions) => {
        const users = selectedOptions.map(option => option.value);
        setSelectedUsers(users);
    };

    const statusColors = {
        new: '#D1FAE5', // green-100
        sent: '#DBEAFE', // blue-100
        success: '#FEF3C7', // yellow-100
        failed: '#FEE2E2', // red-100
        processing: '#fcd34d', // amber-500
        default: '#F3F4F6', // gray-100

    };

    const getColor = (status) => {
        return statusColors[status] || statusColors.default;
    };

    const customStyles = {
        control: (styles) => ({...styles, backgroundColor: 'white'}),
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            const color = getColor(data.value);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? color
                        : isFocused
                            ? color
                            : null,
                color: isFocused
                    ? 'black'
                    : isSelected
                        ? 'white'
                        : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? color : color),
                },
            };
        },
        multiValue: (styles, {data}) => {
            const color = getColor(data.value);
            return {
                ...styles,
                backgroundColor: color,
            };
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            color: 'black',
        }),
        multiValueRemove: (styles, {data}) => ({
            ...styles,
            color: 'black',
            ':hover': {
                backgroundColor: getColor(data.value),
                color: 'white',
            },
        }),
    };

    const deleteResult = async (resultId) => {
        try {
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
            const response = await axios.post(apiBaseUrl + `/deleteResult`, resultId, {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                }
            });
            setResults(results.filter(result => result.id !== resultId))
        } catch (error) {
            showError('Hata Oluştu:', error);
        }
    }

    const regenerateResult = async (resultId) => {
        try {
            setResults(results.map(result => result.id === resultId ? {
                ...result,
                status: 'processing',
                error_message: ''
            } : result))
            const token = localStorage.getItem('token'); // Token'ı localStorage'dan alın
            const response = await axios.post(apiBaseUrl + `/regenerateResult`, resultId, {
                headers: {
                    Authorization: `Bearer ${token}` // Token'ı Authorization header'ına ekleyin
                }
            }).then(r => {
                setResults(results.map(result => result.id === resultId ? r.data : result))
            });
            // setResults(results.filter(result => result.id !== resultId))
        } catch (error) {
            showError(error.response?.data?.detail);
            setResults(results.map(result => result.id === resultId ? {
                ...result,
                status: 'inProgress',
                error_message: ''
            } : result))
        }
    }


    return (
        <div className="flex flex-col items-center">
            <div className="mb-4 flex flex-col  md:w-2/4">
                <Select
                    isMulti
                    name="statuses"
                    placeholder={"Durumlar"}
                    options={options}
                    className="color-multi-select mb-2"
                    classNamePrefix="select"
                    onChange={handleStatusChange}
                    value={options.filter(option => statuses.includes(option.value))}
                    styles={customStyles}
                />
                <div className="flex flex-col md:flex-row w-full justify-between">
                    <input
                        type="text"
                        className="p-1 mb-1 border border-gray-300 rounded w-full md:w-1/2 md:p-0 md:mb-0"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        placeholder=" Dosya Adı"
                        onKeyDown={handleKeyDown} // Enter tuşuna basıldığında fetchResults'ı tetikler
                    />
                    <Select
                        isMulti
                        name="users"
                        placeholder={"Kullanıcılar"}
                        options={userNames}
                        className="md:ml-2 pb-0 w-full md:w-1/2"
                        classNamePrefix="select"
                        onChange={handleSelectedUsersChange}
                        value={userNames.filter(option => selectedUsers.includes(option.value))}
                    />
                </div>
                <div className="mt-2 flex flex-row justify-between">
                    <input
                        type="date"
                        className="p-2 border border-gray-300 rounded w-1/3"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        onKeyDown={handleKeyDown} // Enter tuşuna basıldığında fetchResults'ı tetikler
                    />
                    <input
                        type="date"
                        className="p-2  border border-gray-300 rounded w-1/3"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        onKeyDown={handleKeyDown} // Enter tuşuna basıldığında fetchResults'ı tetikler
                    />

                    <button
                        onClick={fetchResults}
                        className="p-2 bg-blue-500 text-white rounded flex items-center justify-center w-1/3"
                        disabled={isLoading} // Yükleniyor durumundayken butonu devre dışı bırak
                    >
                        {isLoading ? (
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                            'Filtrele'
                        )}
                    </button>

                </div>
            </div>
            {!isLoading ? <div className={"w-10/12 md:w-1/2 items-center"}>
                    {results && <div className="flex justify-between items-center p-4">
                        <div className="text-center">
                            <h2 className="font-bold text-gray-800">Toplam</h2>
                            <div className="text-sm text-gray-600">{results.length}</div>
                        </div>
                        <div className="text-center">
                            <h2 className="font-bold text-green-500">Yeni</h2>
                            <div className="text-sm text-green-600">{results.filter(f => f.status === 'new').length}</div>
                        </div>
                        <div className="text-center">
                            <h2 className="font-bold text-sky-500">Görüldü</h2>
                            <div className="text-sm text-sky-600">{results.filter(f => f.status === 'seen').length}</div>
                        </div>
                        <div className="text-center">
                            <h2 className="font-bold text-red-500">Hatalı</h2>
                            <div className="text-sm text-red-600">{results.filter(f => f.status === 'failed').length}</div>
                        </div>
                        <div className="text-center">
                            <h2 className="font-bold text-amber-500">Tamamlandı</h2>
                            <div
                                className="text-sm text-amber-600">{results.filter(f => f.status === 'success').length}</div>
                        </div>
                    </div>}
                    {results && results.length > 0 ? (
                        results
                            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sonuçları tarihe göre sırala
                            .map((result, index) => (
                                <div
                                    key={index}
                                    className={`p-2 mb-2 shadow-md border border-b-cyan-500 rounded-md flex flex-row ${getStatusColor(result.status)}`}
                                >
                                    <div className={"w-8"}>
                                        {index + 1}.
                                    </div>
                                    <div
                                        className={`w-10/12 text-sm overflow-hidden ${result.status === "seen" || result.status === "new" || result.status === "success" ? 'cursor-pointer' : ''}`}
                                        onClick={() => (result.status === "seen" || result.status === "new" || result.status === "success") && navigateToShowResult(result.id)}
                                    >
                                        <p className="truncate">
                                            <strong>Dosya:</strong> {result.job_id}
                                        </p>
                                        {result.error_message ?
                                            <p className="">
                                                <strong>Hata:</strong> {convertErrorMessages(result.error_message)}
                                            </p> :
                                            ""
                                        }
                                        {result.date && <p className={'text-xs font-thin truncate'}>
                                            <strong>Tarih:</strong> {new Date(result.date).toLocaleString("tr-TR", {timeZone: "Europe/Istanbul"})}
                                        </p>}
                                        {result.user_name && <p className={'text-xs font-thin truncate'}>
                                            <strong>Kullanıcı:</strong> {result.user_name}</p>}
                                    </div>
                                    <div className={"flex w-1/12 justify-end flex-row space-x-2"}>


                                        {result.status === 'failed' && (
                                            <button onClick={() => regenerateResult(result.id)}
                                                    title={"Yeniden Oluştur"}
                                                    className="p-2 text-green-500 hover:text-green-600 rounded flex items-center justify-center w-10 h-10">
                                                <ArrowPathIcon className="h-10 w-10"/>
                                            </button>
                                        )}

                                        {(result.issue) && (
                                            <div className={'relative group'}>
                                                <div
                                                    className="p-2 text-amber-500 rounded flex items-center justify-center w-10 h-10 animate-blink ">
                                                    <ExclamationCircleIcon className="w-10 h-10"/>
                                                </div>
                                                <span
                                                    className="absolute w-auto p-2 m-2 min-w-max left-1/2 bottom-full bg-black text-white text-xs rounded hidden group-hover:block">
                                            {result.issue}
                                            </span>
                                            </div>
                                        )}
                                        {(result.status !== 'failed' && result.status !== 'processing' && result.status !== 'inProgress' && !result.is_institution_connected) && (
                                        <div className={'relative group'}>
                                            <div
                                                className="p-2 text-gray-500 rounded flex items-center justify-center w-10 h-10 animate-blink ">
                                                <BuildingLibraryIcon className="w-10 h-10"/>
                                            </div>
                                        </div>
                                    )}
                                        {(result.status !== 'processing' && result.status !== 'inProgress') && (
                                            <button onClick={() => deleteResult(result.id)}
                                                    title={"Sil"}
                                                    className="p-2 text-red-400 hover:text-red-500 rounded flex items-center justify-center w-10 h-10">
                                                <TrashIcon className="h-10 w-10"/>
                                            </button>
                                        )}

                                        {result.status === 'processing' && (
                                            <div className="flex items-center justify-center">
                                                <div
                                                    className="animate-spin rounded-full h-4 w-4 border-2 border-blue-500 border-t-transparent"></div>
                                            </div>
                                        )}
                                    </div>


                                </div>
                            ))
                    ) : (
                        <p className={"text-center"}>Gösterilecek Sonuç Yok</p>
                    )}
                </div>
                :

                <div className="flex items-center justify-center w-1/2 h-[20vh]">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>

            }


        </div>
    );
};

export default ResultsList;
