import React from 'react';

function SettingsPage(props) {
    return (
        <div>
            <h1>Ayarlar</h1>

        </div>
    );
}

export default SettingsPage;