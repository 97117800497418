import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Select from 'react-select';
import {apiBaseUrl} from "../consts";
import InstitutionSearch from "./InstitutionSearch";
import {useOutletContext} from "react-router-dom";

const TenderInstitutions = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1); // Toplam sayfa sayısını tutmak için
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [inputPage, setInputPage] = useState(1); // Kullanıcının girdiği sayfa numarası
    const [filter, setFilter] = useState("all");
    const [search, setSearch] = useState("");
    const {showError, showWarning, showInformation} = useOutletContext();

    useEffect(() => {
        // Arama metni boş olduğunda veya 3 karakterden fazla olduğunda veri çek
        if (!search || search.length > 3) {
            fetchTenderInstitutions();
        }
    }, [page, pageSize, filter, search]);  // Bağımlılıkları belirle



    useEffect(() => {
        // Her filtre veya arama değiştiğinde sayfayı ilk sayfaya sıfırla
        setPage(1);
        setInputPage(1);
    }, [filter, search]);
    const fetchTenderInstitutions = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${apiBaseUrl}/getTenderInstitutionsAndMatchesPaginated`, {
                params: {page, page_size: pageSize, filter, search},
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const {items, totalPages} = response.data;
            setData(items || []);
            setTotalPages(totalPages || 1); // API'den toplam sayfa sayısını alıyoruz
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const updateInstitution = async (institutionData) => {
        setIsButtonDisabled(true);
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${apiBaseUrl}/createInstitutionMatch`, institutionData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setData(prevData =>
                prevData.map(item =>
                    item.matched_institution?.institutionId === institutionData.institutionId
                        ? {...item, matched_institution: response.data}
                        : item
                )
            );
            showInformation('Kurum bilgisi başarıyla güncellendi!');
        } catch (error) {
            if (error.response?.status === 403) {
                showWarning(`${error.response.data?.detail}`);
            } else if (error.response?.status === 400) {
                showWarning(`${error.response.data?.detail}`);
            } else {
                showError(error.response ? error.response.data?.detail : 'Bilinmeyen hata');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    };

    const handleInstitutionSelectOrChange = async (selectedInstitution, tenderInstitutionName) => {
        if (selectedInstitution && selectedInstitution.institutionNameInSB) {
            await updateInstitution({
                institutionId: selectedInstitution.institutionId,
                institution_name: tenderInstitutionName,
                institution_name_in_sb: selectedInstitution.institutionNameInSB
            });
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
            setInputPage(newPage);
        } else {
            showWarning(`Geçersiz sayfa numarası. Lütfen 1 ile ${totalPages} arasında bir sayı girin.`);
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
    };

    const handleInputPageChange = (event) => {
        setInputPage(Number(event.target.value));
    };

    const handleGoToPage = () => {
        handlePageChange(inputPage);
    };

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption.value);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const filterOptions = [
        {value: 'all', label: 'Hepsi'},
        {value: 'matched', label: 'Eşleşenler'},
        {value: 'unmatched', label: 'Eşleşmeyenler'}
    ];

    return (
        <div className="container mx-auto py-4">
            <div className="mb-4 flex justify-between">
                <input
                    type="text"
                    value={search}
                    onChange={handleSearchChange}
                    placeholder="Kurum Ara..."
                    className="w-1/2 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <Select
                    value={filterOptions.find(option => option.value === filter)}
                    onChange={handleFilterChange}
                    options={filterOptions}
                    className="w-1/6"
                />
            </div>
            {loading ? (
                <div className="text-center">Yükleniyor...</div>
            ) : error ? (
                <div className="text-center text-red-600">{error}</div>
            ) : (
                <div className="shadow-lg rounded-lg overflow-hidden">
                    <table className="min-w-full bg-white border border-gray-200 shadow-sm">
                        <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                İHALEDEKİ KURUM ADI
                            </th>
                            <th className="px-6 py-3 border-b border-gray-200 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                SAĞLIKBANK KURUM ADI
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className={`${item.matched_institution ? 'bg-green-50' : 'bg-white'} hover:bg-gray-100`}>
                                <td className="px-6 py-4 w-1/2 border-b border-gray-200 text-gray-700">{item.tender_institution_name}</td>
                                <td className="px-6 py-4 w-1/2 border-b border-gray-200 text-gray-700">
                                    <InstitutionSearch
                                        selectedId={item.matched_institution?.institutionId}
                                        apiUrl="institutions"
                                        placeholder="Kurum ara"
                                        onInstitutionSelect={(selectedInstitution) =>
                                            handleInstitutionSelectOrChange(selectedInstitution, item.tender_institution_name)
                                        }
                                        debounceTime={300}
                                        isClearable={false}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className="flex justify-between items-center p-4 bg-gray-50 border-t">
                        <button
                            onClick={() => handlePageChange(page - 1)}
                            disabled={page === 1}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed mr-2"
                        >
                            Önceki
                        </button>
                        <div className="flex items-center">
                            <input
                                type="number"
                                value={inputPage}
                                onChange={handleInputPageChange}
                                className="px-2 py-1 border rounded w-16 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            />
                            <button
                                onClick={handleGoToPage}
                                className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700"
                            >
                                Git
                            </button>
                            <span className="mx-2 text-gray-700">Sayfa {page} / {totalPages}</span>
                        </div>
                        <button
                            onClick={() => handlePageChange(page + 1)}
                            disabled={page === totalPages}
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Sonraki
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TenderInstitutions;